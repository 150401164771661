import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { s3URL } from '@mathflat/shared/@common/utils/s3'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

export const S = {
  problemScoringDrawerContentStyle: css`
    height: 100%;
    box-shadow: 10px 4px 20px 0px rgba(0, 0, 0, 0.05);
    border-top: 1px solid ${colors.gray.$300};
    .problem-scoring-drawer-header {
      border-radius: 0;
      background-color: white;
    }

    .explation-submitted-answer-text + .explation-submitted-answer-text {
      margin-left: 4px;
      &::before {
        content: ' ・ ';
      }
    }

    ${mediaQuery.underTablet} {
      height: calc(100dvh - 60px); // 전체 높이 - 헤더 높이
      box-shadow: none;
      border-top: none;
      .problem-scoring-drawer-header {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background-color: transparent;
      }
    }
  `,
  DrawerBodyContent: styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    padding: 0 20px;
    gap: 20px;

    .problem-scoring-drawer-body-content-item {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-top: 20px;

      &:last-of-type {
        padding-bottom: 20px;
      }

      .problem-scoring-drawer-body-content-item-header {
        display: flex;
        justify-content: space-between;
      }

      ${mediaQuery.underTablet} {
        &:last-of-type {
          padding-bottom: 40px;
        }
      }

      + .problem-scoring-drawer-body-content-item {
        border-top: 1px solid ${colors.gray.$300};
      }

      > .title-wrapper {
        display: flex;
        justify-content: space-between;
      }

      .icon-button {
        width: 20px;
        height: 20px;
        transition: transform 0.3s ease-in-out;
      }

      .icon-button.opened {
        transform: rotate(-180deg);
      }

      .title {
        color: ${colors.gray.$900};
        font-weight: bold;
      }

      .note-container {
        min-height: 312px;

        > div {
          width: fit-content;
        }
      }

      .no-video,
      .no-answer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 40px 0;
        ${typo.body02};
        color: ${colors.gray.$700};
        font-weight: 400;
      }

      .problem-image-container {
        min-height: 312px;
      }

      ${mediaQuery.underTablet} {
        .note-container {
          height: 180px;
          min-height: unset;
          position: relative;

          > div {
            width: unset;
          }
        }
        .note-zoom-in {
          position: absolute;
          right: 12px;
          bottom: 12px;
          width: 32px;
          height: 32px;
          border-radius: 4px;
          border: 1px solid ${colors.gray.$400};
          background-color: ${colors.gray.$100};
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .mobile-text {
      min-height: 180px;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.gray.$700};
    }
  `,
  Thumbnail: styled.div`
    width: 100%;
    height: 100%;
    background: url(${s3URL.common('images/video/problem-solving/background.png')}) #fff;

    > .img-bg {
      position: absolute;
      top: 7%;
      left: 30%;
      padding: 4% 4% 0px 4%;

      width: 64%;
      height: 93%;
      background: #fff;
      box-shadow: 0px 4px 30px 0px #0000001a;
    }

    > .gradation {
      position: absolute;
      top: 50%;

      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(251, 252, 255, 0) 0%, #fbfcff 72.4%);

      > .title {
        position: absolute;
        top: 40%;
        left: 7%;
      }
      > .sub-title {
        position: absolute;
        top: 20%;
        left: 7%;
      }
    }
  `,
}
