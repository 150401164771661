// 스키마 작성하기 힘들어서 일단 타입으로 작성하는 파일.
// 추후 스키마 작성할 때 참고

import type { Entity as CurriculumEntity } from '~/@entities/Curriculum/dto'

export namespace Entity {
  export class Lecture {
    curriculumId: CurriculumEntity.Curriculum['id']
    id: number
    orderingNumber: number
    thumbnailUrl: string
    title: string
    videoUrl: string
    subtitleUrl: string | null

    constructor(params: Lecture) {
      this.curriculumId = params.curriculumId
      this.id = params.id
      this.orderingNumber = params.orderingNumber
      this.thumbnailUrl = params.thumbnailUrl
      this.title = params.title
      this.videoUrl = params.videoUrl
      this.subtitleUrl = params.subtitleUrl
    }
  }
}
