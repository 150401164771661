import { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto.ts'
import type { Entity as StudentEntity } from '@mathflat/domain/@entities/Student/dto'
import { LearningProcessApi } from '@mathflat/domain/@entities/StudentLearningProcess/api'
import { StudentWorksheetApi } from '@mathflat/domain/@entities/StudentWorksheet/api'
import { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'
import { Entity as StudentWorksheetScoringEntity } from '@mathflat/domain/@entities/StudentWorksheet/StudentWorksheetScoring/dto'
import { cloneDeep, isEqual } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'

import { RequestScoring, studentWorksheetApi } from '~/@common/api'
import { toastService } from '~/@common/services'
import { remoteStorageService } from '~/@common/services/remoteStorage.service'
import { localStorageService } from '~/@common/services/storage.service'
import { apiSpecFetcher } from '~/@common/utils/apiSpecFetcher'
import maxios from '~/@common/utils/maxios'
import { getAnswerHideFlag } from '~/@common/utils/scoringUtils'
import {
  ProblemScoring,
  ProblemScoringColl,
} from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import type {
  External,
  Process,
  ProcessInfo,
  RelationId,
  Step,
  개념학습단계,
  결과확인단계,
  문제풀이단계,
  오답학습단계,
  챌린지학습_시작정보,
} from '~/@pages/student/learning-process/@common/types/LearningProcess.type.ts'
import { ProcessController } from '~/@pages/student/learning-process/@widgets/service/ProcessController.ts'
import { AssignedStudentWorksheetHomeworksSelfLearnings } from '~/@pages/student/student-worksheet/@common/model'

import {
  type ConstructedReference,
  LearningProcessReference,
} from '../../@common/hooks/useGetLearningProcessReference'

const 개념학습단계_기본: 개념학습단계 = {
  step: '개념학습',
  lastScreen: 0,
  screen: [{ lastElement: 0, title: '개념학습' }],
}
const 문제풀이단계_기본: 문제풀이단계 = {
  step: '문제풀이',
  lastScreen: 0,
  screen: [{ lastElement: 0, title: '문제풀이' }],
}
const 결과확인단계_기본: 결과확인단계 = {
  step: '결과확인',
  lastScreen: 0,
  screen: [],
}
const 오답학습단계_기본: 오답학습단계 = { step: '오답 유형학습', lastScreen: 0, screen: [] }

const PROCESS: {
  오답학습: [개념학습단계, 오답학습단계]
  심화학습: [문제풀이단계, 결과확인단계]
  유형챌린지: [개념학습단계, 문제풀이단계, 결과확인단계, 오답학습단계]
  소단원챌린지: [개념학습단계, 문제풀이단계, 결과확인단계, 오답학습단계]
} = {
  오답학습: [개념학습단계_기본, 오답학습단계_기본],
  심화학습: [문제풀이단계_기본, 결과확인단계_기본],
  유형챌린지: [개념학습단계_기본, 문제풀이단계_기본, 결과확인단계_기본, 오답학습단계_기본],
  소단원챌린지: [개념학습단계_기본, 문제풀이단계_기본, 결과확인단계_기본, 오답학습단계_기본],
}

export const REFERENCE_TYPE_STUDENT_WORKSHEET = 'STUDENTWORKSHEET'

export class LearningProcessService {
  // remote storage 관련 정보
  static readonly __version = '0.0.1'
  private static lastTimestamp = 0

  // 학습절차
  private _processInfo: ProcessInfo = {
    lastStep: 0,
    process: [],
    relationIds: [],
  }
  private _chapterName: string = ''
  private _reference: ConstructedReference | '' = '' // ex) LITTLE_CHAPTER.1234, STUDENTWORKSHEET.345678 => 리모트 조회시에 사용

  // 개념
  private _개념학습정보: LearningProcessApi.개념학습하기.Output | null = null

  // 챌린지학습 관련 정보 (챌린지의 2단계 챌린지 문제풀이, 심화학습)
  private _학습모듈_문제풀이: {
    problemScoringColl: ProblemScoringColl<'WORKSHEET'> | undefined
    problemScoringViewOption: ProblemScoringViewOption<'학습모듈'> | undefined
    studentWorksheet: StudentWorksheetEntity.StudentWorksheet | undefined
    worksheet: WorksheetEntity.ChanllengeWorksheet | undefined
  } = {
    problemScoringColl: undefined,
    problemScoringViewOption: undefined,
    studentWorksheet: undefined,
    worksheet: undefined,
  }

  // 자기주도학습 관련 정보(챌린지 4단계 오답유형학습, 오답학습)
  private _오답유형학습_쌍둥이유사_문제풀이_리스트: {
    problemScoringColl: ProblemScoringColl<'WORKSHEET'>
    problemScoringViewOption: ProblemScoringViewOption<'학습모듈'>
    studentWorksheet: StudentWorksheetEntity.StudentWorksheet
    worksheet: WorksheetEntity.WrongConceptWorksheet | WorksheetEntity.LevelUpWorksheet
  }[] = []

  // 오답유형학습 문풀동, 틀린문제, 쌍둥이문제 정보
  private _오답유형학습_리스트: LearningProcessApi.오답학습하기.Output = []

  // 보충오답학습 관련 학습지 리스트(부모-prev, 형제-next)
  private _보충_오답학습_리스트: AssignedStudentWorksheetHomeworksSelfLearnings | null = null

  constructor() {
    makeAutoObservable(this)
  }

  // 학습절차
  async patchProcessInfo(reference: string, processInfo: ProcessInfo) {
    if (reference === '') return

    this._processInfo = processInfo
    LearningProcessService.lastTimestamp = Date.now()

    const externalData: External = {
      ...this._processInfo,
      __version: LearningProcessService.__version,
      __kind: 'ms_LearningProcess',
      __timestamp: LearningProcessService.lastTimestamp,
    }

    await remoteStorageService.patch(reference, cloneDeep(externalData))
  }

  changeStep(stepId: number) {
    const 새로운_절차정보 = new ProcessController(this._processInfo).updateStep(stepId).processInfo
    this.patchProcessInfo(this._reference, 새로운_절차정보)
  }

  goToNextStep() {
    if (this.currentStep + 1 >= this.process.length) return

    const 새로운_절차정보 = new ProcessController(this._processInfo).updateStep(
      this.currentStep + 1,
    ).processInfo
    this.patchProcessInfo(this._reference, 새로운_절차정보)
  }

  goToPrevStep() {
    if (this.currentStep < 1) return

    const 새로운_절차정보 = new ProcessController(this._processInfo).updateStep(
      this.currentStep - 1,
    ).processInfo
    this.patchProcessInfo(this._reference, 새로운_절차정보)
  }

  changeStepAndScreen(stepId: number, screenId: number) {
    const 새로운_절차정보 = new ProcessController(this._processInfo)
      .updateScreen(stepId, screenId)
      .updateStep(stepId).processInfo
    this._processInfo = 새로운_절차정보
    this.patchProcessInfo(this._reference, 새로운_절차정보)
  }

  // step, screen, element 모두 변경
  async changeElement(stepId: number, screenId: number, elementId: number) {
    const 새로운_절차정보 = new ProcessController(this._processInfo)
      .updateElement(stepId, screenId, elementId)
      .updateScreen(stepId, screenId)
      .updateStep(stepId).processInfo
    this.patchProcessInfo(this._reference, 새로운_절차정보)
  }

  async 챌린지학습지_상태변경(params: StudentWorksheetApi.챌린지학습지_상태변경.Params) {
    await apiSpecFetcher(StudentWorksheetApi.챌린지학습지_상태변경.spec)({
      params,
    })
  }

  async 챌린지학습지_생성(params: StudentWorksheetApi.챌린지학습지생성.Params) {
    const { data } = await apiSpecFetcher(StudentWorksheetApi.챌린지학습지생성.spec)({
      params,
    })

    return data.assignList[0].studentWorksheetId
  }

  async 챌린지학습지_조회(params: StudentWorksheetApi.챌린지학습지조회.Params) {
    const { data } = await apiSpecFetcher(StudentWorksheetApi.챌린지학습지조회.spec)({
      params,
    })
    return data
  }

  async 챌린지학습_시작하기(
    params: StudentWorksheetApi.챌린지학습지조회.Params,
  ): Promise<챌린지학습_시작정보> {
    const 조회결과 = await this.챌린지학습지_조회(params)
    const reference = new LearningProcessReference(params).constructedReference

    if (!조회결과 || 조회결과.learningStep === 'RESOLVED') {
      return {
        이어하기_여부: false,
        새로하기: async () => {
          const studentWorksheetId = await this.챌린지학습지_생성(params)
          await this.챌린지학습지_새로하기({
            type: params.referenceType === 'LITTLE_CHAPTER' ? '소단원챌린지' : '유형챌린지',
            reference,
            studentWorksheetId: `${studentWorksheetId}`,
          })
          return `/student/learning-process/${studentWorksheetId}?reference=${reference}`
        },
      }
    }

    return {
      이어하기_여부: true,
      새로하기: async () => {
        const studentWorksheetId = await this.챌린지학습지_생성(params)
        await this.챌린지학습지_새로하기({
          type: '유형챌린지',
          reference,
          studentWorksheetId: `${studentWorksheetId}`,
        })
        return `/student/learning-process/${studentWorksheetId}?reference=${reference}`
      },
      이어하기: async () => `/student/learning-process/${조회결과.id}?reference=${reference}`,
    }
  }

  async 챌린지학습_완료하기(studentWorksheetId: number) {
    this.챌린지학습지_상태변경({ studentWorksheetId, learningStep: 'RESOLVED' })
  }

  async 챌린지학습지_새로하기({
    type,
    reference,
    studentWorksheetId,
  }: {
    reference: string
    studentWorksheetId: string
    type: Process
  }) {
    const process = PROCESS[type]
    const relationIds: RelationId[] = [
      {
        id: studentWorksheetId,
        relationStep: '개념학습',
      },
      {
        id: studentWorksheetId,
        relationStep: '문제풀이',
      },
      {
        id: studentWorksheetId,
        relationStep: '결과확인',
      },
    ]
    const processInfo: ProcessInfo = {
      lastStep: 1, // 문제풀이단계부터 시작
      process: process,
      relationIds: relationIds,
    }

    await this.patchProcessInfo(reference, processInfo)
  }

  async 보충_오답학습지_생성(params: StudentWorksheetApi.보충_오답학습지생성.RouteParams) {
    const { data } = await maxios.post(
      '/student-learning/self-learning/wrong',
      {},
      {
        params,
      },
    )

    return data
  }

  // assignList 리턴
  async 보충_심화학습지_생성(params: StudentWorksheetApi.보충_심화학습지생성.RouteParams) {
    const { data } = await apiSpecFetcher(StudentWorksheetApi.보충_심화학습지생성.spec)({
      params,
    })

    return data
  }

  async 보충_오답학습지_시작하기({ studentWorksheetId }: { studentWorksheetId: string }) {
    const reference = new LearningProcessReference({
      referenceType: REFERENCE_TYPE_STUDENT_WORKSHEET,
      referenceId: studentWorksheetId,
    }).constructedReference

    const 이전절차정보 = await this.getLearningProcessInfo(reference)
    if (이전절차정보[reference]) return

    const process = PROCESS['오답학습'].map((step) =>
      step.step === '오답 유형학습'
        ? { ...step, screen: [{ lastElement: 0, title: '오답 유형학습' }] }
        : step,
    )
    const relationIds: RelationId[] = [
      {
        id: studentWorksheetId,
        relationStep: '개념학습',
      },
      {
        id: studentWorksheetId,
        relationStep: '오답 유형학습',
      },
    ]
    const processInfo: ProcessInfo = {
      lastStep: this.currentStep,
      process: process,
      relationIds: relationIds,
    }
    await this.patchProcessInfo(reference, processInfo)
  }

  async 보충_오답학습지_학습하기({ studentWorksheetId }: { studentWorksheetId: string }) {
    const reference = new LearningProcessReference({
      referenceType: REFERENCE_TYPE_STUDENT_WORKSHEET,
      referenceId: studentWorksheetId,
    }).constructedReference

    const 이전절차정보 = await this.getLearningProcessInfo(reference)

    if (이전절차정보[reference]) {
      this._processInfo = 이전절차정보[reference]
      return `/student/learning-process/${studentWorksheetId}?reference=${reference}`
    }

    const process = PROCESS['오답학습'].map((step) =>
      step.step === '오답 유형학습'
        ? { ...step, screen: [{ lastElement: 0, title: '오답 유형학습' }] }
        : step,
    )
    const relationIds: RelationId[] = [
      {
        id: studentWorksheetId,
        relationStep: '개념학습',
      },
      {
        id: studentWorksheetId,
        relationStep: '오답 유형학습',
      },
    ]
    const processInfo: ProcessInfo = {
      lastStep: this.currentStep,
      process,
      relationIds,
    }
    await remoteStorageService.patch(reference, processInfo)
    this._processInfo = processInfo

    return `/student/learning-process/${studentWorksheetId}?reference=${reference}`
  }

  async 보충_심화학습지_학습하기({ studentWorksheetId }: { studentWorksheetId: string }) {
    const reference = new LearningProcessReference({
      referenceType: REFERENCE_TYPE_STUDENT_WORKSHEET,
      referenceId: studentWorksheetId,
    }).constructedReference

    const 이전절차정보 = await this.getLearningProcessInfo(reference)

    if (이전절차정보[reference]) {
      this._processInfo = 이전절차정보[reference]
      return `/student/learning-process/${studentWorksheetId}?reference=${reference}`
    }

    const process = PROCESS['심화학습']
    const relationIds: RelationId[] = [
      { id: studentWorksheetId, relationStep: '문제풀이' },
      { id: studentWorksheetId, relationStep: '결과확인' },
    ]
    const processInfo: ProcessInfo = {
      lastStep: this.currentStep,
      process,
      relationIds,
    }
    await remoteStorageService.patch(reference, processInfo)
    this._processInfo = processInfo

    return `/student/learning-process/${studentWorksheetId}?reference=${reference}`
  }

  private async getLearningProcessInfo(reference: string) {
    return remoteStorageService.get(reference)
  }

  async initProcess(reference: ConstructedReference) {
    // 절차 데이터가 있는 경우에 Remote를 호출하지 않고 기존 절차 데이터 사용.
    if (this.process.length > 0) {
      this._reference = reference
      this.initChallengeWorksheetProblems()
      return
    }

    const 이전절차정보 = await this.getLearningProcessInfo(reference)
    runInAction(() => {
      if (이전절차정보[reference]) {
        this._processInfo = 이전절차정보[reference]
        this._reference = reference

        this.initChallengeWorksheetProblems()
      }
    })
  }

  resetProcess() {
    LearningProcessService.lastTimestamp = 0

    this._processInfo = {
      lastStep: 0,
      process: [],
      relationIds: [],
    }
    this._reference = ''
    this._chapterName = ''
    this._개념학습정보 = null
    this._오답유형학습_리스트 = []
    this._보충_오답학습_리스트 = null
    this._오답유형학습_쌍둥이유사_문제풀이_리스트 = []
    this._학습모듈_문제풀이 = {
      problemScoringColl: undefined,
      problemScoringViewOption: undefined,
      studentWorksheet: undefined,
      worksheet: undefined,
    }
  }

  get chapterName() {
    return this._chapterName
  }

  get currentStep() {
    return this._processInfo.lastStep
  }

  get currentStatus() {
    if (this.process.length < 1) {
      return {
        step: 0,
        screen: 0,
        element: 0,
      }
    }

    return {
      step: this.currentStep,
      screen: this.process[this.currentStep].lastScreen,
      element:
        this.process[this.currentStep].screen[this.process[this.currentStep].lastScreen]
          ?.lastElement,
    }
  }

  get process() {
    return this._processInfo.process
  }

  get relationIds() {
    return this._processInfo.relationIds
  }

  get relationId() {
    return this.relationIds.find(
      (item) => item.relationStep === this.process[this.currentStep].step,
    )?.id
  }

  // 개념학습단계
  get 개념학습정보() {
    return this._개념학습정보
  }

  async 개념학습정보_조회() {
    if (this._개념학습정보) return

    const relationId = this.relationIds.find(
      (relationId) => relationId.relationStep === '개념학습',
    )?.id

    if (!relationId) return

    const { data } = await apiSpecFetcher(LearningProcessApi.개념학습하기.spec)({
      params: {
        studentWorksheetId: relationId,
      },
    })

    runInAction(() => {
      this._개념학습정보 = data
    })
  }

  async postVideo(params: { lectureId: number; studentId?: StudentEntity.Student['id'] }) {
    return maxios.post(`student-lecture`, params)
  }

  // 문제풀이단계
  get 학습모듈_문제풀이() {
    return this._학습모듈_문제풀이
  }

  async getChallengAndUpdateProblemScoring(
    studentWorksheetId: StudentWorksheetEntity.StudentWorksheet['id'],
  ) {
    const { worksheet, studentWorksheet } =
      await studentWorksheetApi.getAssignedStudentWorksheetById(studentWorksheetId)

    const answerHideFlag = false

    const problemScoringsParams = answerHideFlag
      ? {
          answerHideFlag: true,
        }
      : undefined

    const problemScorings = await studentWorksheetApi.getAssignedStudentWorksheetWithProblems(
      studentWorksheetId,
      problemScoringsParams,
    )

    runInAction(() => {
      this._학습모듈_문제풀이.worksheet = new WorksheetEntity.ChanllengeWorksheet(worksheet)
      this._학습모듈_문제풀이.studentWorksheet = new StudentWorksheetEntity.StudentWorksheet(
        studentWorksheet,
      )
      this._학습모듈_문제풀이.problemScoringColl = new ProblemScoringColl<'WORKSHEET'>(
        problemScorings.map(
          ({ problem, worksheetProblemId, scoring, handwrittenNoteUrl }, index) =>
            new ProblemScoring<'WORKSHEET'>(
              {
                scoring: new StudentWorksheetScoringEntity.StudentWorksheetScoring({
                  scoring: { ...scoring, worksheetProblemId },

                  problem: {
                    ...problem,
                    answerUnits: problem.answerUnits,
                    keypadTypes: problem.keypadTypes,
                  },
                  worksheet: {
                    autoScorable: worksheet?.autoScorable ?? false,
                    label: `${index + 1}번`, // label을 밖에서 주입해줘야 하는 경우에는 받을 수 있도록 (ex. 1번 유사문제)}
                  },
                }),
              },
              worksheetProblemId,
              handwrittenNoteUrl,
            ),
        ),
      )
      this._chapterName = worksheet.chapter

      this._학습모듈_문제풀이.problemScoringViewOption = new ProblemScoringViewOption({
        content: {
          status: studentWorksheet.status,
          autoScored: worksheet.autoScorable,
          kind: 'WORKSHEET',
          type: worksheet.type,
          worksheetId: worksheet.id,
        },
        studentAppSetting: null,
      })
    })
  }

  async loadAndSetSelfLearningStudentWorksheets(studentWorksheetId: string) {
    const {
      data: { nextStudentWorksheetAndWorksheets },
    } = await studentWorksheetApi.getSelfLearningStudentWorksheets({
      studentWorksheetId,
    })

    const currentStudentWorksheet = nextStudentWorksheetAndWorksheets.find(
      (item) => item.id === +studentWorksheetId,
    )

    if (!currentStudentWorksheet) return

    const { worksheet, ...studentWorksheet } = currentStudentWorksheet

    const group = nextStudentWorksheetAndWorksheets.map(({ worksheet, ...studentWorksheet }) => {
      return {
        studentWorksheet: new StudentWorksheetEntity.StudentWorksheet(
          studentWorksheet as ConstructorParameters<
            typeof StudentWorksheetEntity.StudentWorksheet
          >[0],
        ),
        worksheet: worksheet as
          | WorksheetEntity.LevelUpWorksheet
          | WorksheetEntity.WrongConceptWorksheet,
      }
    })

    runInAction(() => {
      this._보충_오답학습_리스트 = new AssignedStudentWorksheetHomeworksSelfLearnings({
        worksheet,
        studentWorksheet: new StudentWorksheetEntity.StudentWorksheet(studentWorksheet),
        group: group,
      })
    })
  }

  async onSubmitWorksheetProblems(studentWorksheetId: string) {
    try {
      const payload = this._학습모듈_문제풀이.problemScoringColl?.toScoredArr

      if (
        payload &&
        payload.length > 0 &&
        payload[0].localUserInput instanceof RequestScoring.자동채점
      ) {
        await studentWorksheetApi.patchAutoScoringAssignedStudentWorksheet(
          studentWorksheetId,
          payload.map(({ localUserInput }) =>
            RequestScoring.자동채점.toRequestParams<'WORKSHEET', '일반'>(
              localUserInput as RequestScoring.자동채점,
            ),
          ),
        )

        payload?.forEach((item) => {
          localStorageService.removeScoringData({ scoringId: item.id })
        })

        await this.getChallengAndUpdateProblemScoring(+studentWorksheetId)
      }
    } catch (error: any) {
      if (error?.response?.data?.code === 'STUDENT_WORKSHEET_PROBLEM_DUPLICATE_SUBMIT') {
        toastService.error('이미 제출한 답안은 수정할 수 없습니다.', { allowMultipleToast: true })
      }
    }
  }

  initChallengeWorksheetProblems() {
    const relationId = this.relationIds[0].id
    if (!relationId) return
    this.getChallengAndUpdateProblemScoring(+relationId)
  }

  // 결과확인단계
  get 오답유형학습_활성화_여부() {
    return (
      Number(this.process.find((eachStep) => eachStep.step === '오답 유형학습')?.screen.length) > 0
    )
  }

  async 오답_유형학습_시작하기() {
    if (!this.relationId) return

    const { assignList } = await this.보충_오답학습지_생성({
      studentWorksheetId: +this.relationId,
    })

    if (!assignList || assignList.length === 0) {
      this.relationId && this.챌린지학습_완료하기(+this.relationId)
      return
    }

    const newRelationIds: RelationId[] = [
      ...this._processInfo.relationIds,
      {
        relationStep: '오답 유형학습',
        id: '' + assignList[0].studentWorksheetId,
      },
    ]

    const 새로운_절차정보: ProcessInfo = {
      ...this._processInfo,
      relationIds: newRelationIds,
      lastStep: this.currentStep + 1,
    }

    await this.patchProcessInfo(this._reference, 새로운_절차정보)
  }

  // 오답학습단계

  // 전체 오답유형학습에 대한 정보
  get 오답유형학습_리스트() {
    return this._오답유형학습_리스트
  }

  // 오답유형학습 각 탭 문제들 리스트에 대한 탭 리스트
  get 오답유형학습_쌍둥이유사_문제풀이_리스트() {
    return this._오답유형학습_쌍둥이유사_문제풀이_리스트
  }

  // 현재 바라보고 있는 탭의 쌍둥이 유사 문제들
  get 오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트() {
    if (this.currentStatus.screen + 1 > this._오답유형학습_쌍둥이유사_문제풀이_리스트.length) {
      return this._오답유형학습_쌍둥이유사_문제풀이_리스트[0]
    }
    return this._오답유형학습_쌍둥이유사_문제풀이_리스트[this.currentStatus.screen]
  }

  // 문풀동 정보
  get 오답유형학습_문제풀이동영상() {
    return {
      concept: this._오답유형학습_리스트[this.currentStatus.screen].wrongLearningKey.concept,
      problem: this._오답유형학습_리스트[this.currentStatus.screen].wrongLearningKey.problem,
    }
  }

  // 틀린문제 정보
  get 오답유형학습_틀린문제() {
    const wrongProblemList = this._오답유형학습_리스트[
      this.currentStatus.screen
    ].prevScoringToPairOrSimilarScorings.map(
      ({ prevScoring }) =>
        new ProblemScoring<'WORKSHEET'>(
          {
            scoring: new StudentWorksheetScoringEntity.StudentWorksheetScoring<'일반'>({
              scoring: {
                userAnswer: prevScoring.studentWorksheetScoring.userAnswer,
                result: prevScoring.studentWorksheetScoring.result,
                worksheetProblemId: prevScoring.studentWorksheetScoring.worksheetProblemId,
              },
              problem: prevScoring.problem,
              worksheet: {
                autoScorable: prevScoring.problem.autoScored,
                label: `${prevScoring.worksheetProblem.orderingNumber}번`,
              },
            }),
          },
          prevScoring.studentWorksheetScoring.worksheetProblemId,
          prevScoring.studentWorksheetScoring.handwrittenNoteUrl,
        ),
    )
    return wrongProblemList
  }

  get 모든_오답유형학습_쌍둥이유사_문제_리스트() {
    return this._오답유형학습_쌍둥이유사_문제풀이_리스트
      .map((scoring) => scoring.problemScoringColl?.toArr)
      .flat()
  }

  get unMarkedProblemIndexes() {
    let result = { screenIndex: 0, elementIndex: 0 }

    const notLocalyProblem = this._오답유형학습_쌍둥이유사_문제풀이_리스트
      .map((worksheetScoring) =>
        worksheetScoring.problemScoringColl?.toNoneArr.filter(
          (problemScoring) => problemScoring.localUserInput.isNotLocallyScored,
        ),
      )
      .flat()[0]

    const allProblemScoringColl = this._오답유형학습_쌍둥이유사_문제풀이_리스트.map(
      (worksheetScoring) => worksheetScoring.problemScoringColl,
    )

    allProblemScoringColl.forEach((eachScreenProblemColl, screenIndex) => {
      const elementIndex = eachScreenProblemColl?.toArr.findIndex((problem) =>
        isEqual(problem, notLocalyProblem),
      )
      if (elementIndex !== -1) {
        result = { screenIndex, elementIndex }
      }
    })

    return result
  }

  get isLastScreen() {
    return this.currentStatus.screen + 1 === this._오답유형학습_쌍둥이유사_문제풀이_리스트.length
  }

  get isAllMarking() {
    return (
      this._오답유형학습_쌍둥이유사_문제풀이_리스트
        .map((worksheetScoring) => worksheetScoring.problemScoringColl?.toNoneArr)
        .flat().length ===
      this._오답유형학습_쌍둥이유사_문제풀이_리스트
        .map((worksheetScoring) => worksheetScoring.problemScoringColl?.toScoredArr)
        .flat().length
    )
  }

  get 보충_오답학습_리스트() {
    return this._보충_오답학습_리스트
  }

  get isAllScoredPairSimilarWorksheet() {
    if (this.모든_오답유형학습_쌍둥이유사_문제_리스트.length === 0) return false
    return this.모든_오답유형학습_쌍둥이유사_문제_리스트.every((scoring) => scoring?.isSubmitted)
  }

  async initWrongConceptSelfLearning() {
    const relationId = this.relationIds.find(
      (relationId) => relationId.relationStep === '오답 유형학습',
    )?.id

    if (!relationId) return

    // 오답유형학습 전체리스트 fetch하여 적용
    await this.getWrongLearningListAndUpdateScoring(relationId)

    this.오답유형학습_활성화()

    // 형제 오답학습 가져오기
    await this.loadAndSetSelfLearningStudentWorksheets(relationId)

    // 채점이 완료된 학습지의 경우
    if (
      this._오답유형학습_쌍둥이유사_문제풀이_리스트[this.currentStatus.screen].studentWorksheet
        ?.status === 'COMPLETE' &&
      this.relationId
    ) {
      await this.getWrongLearningListAndUpdateScoring(relationId)
    }
  }

  async updatePairAndSimilarScoring(relationId: string) {
    const { worksheet, studentWorksheet } =
      await studentWorksheetApi.getAssignedStudentWorksheetById(parseInt(relationId))

    const 모든쌍둥이유사문제 = this._오답유형학습_리스트.map((item) =>
      item.prevScoringToPairOrSimilarScorings
        .map((scorings) => [
          ...scorings.pairScorings.map((scoring) => ({
            ...scoring,
            label: `${scorings.prevScoring.worksheetProblem.orderingNumber}번 쌍둥이문제`,
            problem: { ...scoring.problem, conceptName: item.wrongLearningKey.concept.name },
          })),
          ...scorings.similarScorings.map((scoring) => ({
            ...scoring,
            label: `${scorings.prevScoring.worksheetProblem.orderingNumber}번 유사문제`,
            problem: { ...scoring.problem, conceptName: item.wrongLearningKey.concept.name },
          })),
        ])
        .flat(),
    )

    runInAction(() => {
      this._오답유형학습_쌍둥이유사_문제풀이_리스트 = 모든쌍둥이유사문제.map((problemList) => {
        return {
          studentWorksheet: new StudentWorksheetEntity.StudentWorksheet(studentWorksheet),
          // 심화학습인지 오답학습인지 찾을 수 있는 값
          // worksheet: '심화'
          //   ? new WorksheetEntity.LevelUpWorksheet(worksheet)
          //   : new WorksheetEntity.WrongConceptWorksheet(worksheet),
          worksheet: new WorksheetEntity.WrongConceptWorksheet(worksheet),
          problemScoringColl: new ProblemScoringColl<'WORKSHEET'>(
            problemList.map(
              ({ problem, label, studentWorksheetScoring }) =>
                new ProblemScoring<'WORKSHEET'>(
                  {
                    scoring: new StudentWorksheetScoringEntity.StudentWorksheetScoring({
                      scoring: studentWorksheetScoring,
                      problem,
                      worksheet: {
                        autoScorable: worksheet.autoScorable,
                        label,
                      },
                    }),
                  },
                  studentWorksheetScoring.worksheetProblemId,
                  studentWorksheetScoring.handwrittenNoteUrl,
                ),
            ),
          ),
          problemScoringViewOption: new ProblemScoringViewOption({
            content: {
              status: studentWorksheet.status,
              autoScored: worksheet.autoScorable,
              kind: 'WORKSHEET',
              type: worksheet.type,
              worksheetId: worksheet.id,
            },
            studentAppSetting: null,
          }),
        }
      })
    })
  }

  async 결과확인_활성화() {
    if (this.process.filter((eachStep) => eachStep.step === '결과확인')[0]?.screen.length > 0)
      return

    const 결과확인단계: Step = {
      step: '결과확인',
      lastScreen: 0,
      screen: [{ lastElement: 0, title: '결과확인' }],
    }

    const 새로운_절차정보 = new ProcessController(this._processInfo)
      .updateProcess(결과확인단계)
      .updateStep(this.currentStep + 1).processInfo
    this.patchProcessInfo(this._reference, 새로운_절차정보)
  }

  오답유형학습_활성화() {
    if (this._오답유형학습_리스트.length === 0) {
      const 오답유형학습단계: Step = {
        step: '오답 유형학습',
        lastScreen: 0,
        screen: [{ lastElement: 0, title: '오답 유형학습' }],
      }
      const 새로운_절차정보 = new ProcessController(this._processInfo).updateProcess(
        오답유형학습단계,
      ).processInfo
      this.patchProcessInfo(this._reference, 새로운_절차정보)
      return
    }

    if (
      this.process.filter((eachStep) => eachStep.step === '오답 유형학습')[0]?.screen.length ===
      this._오답유형학습_리스트.length
    )
      return

    const 오답유형학습단계: Step = {
      step: '오답 유형학습',
      lastScreen: 0,
      screen: this._오답유형학습_리스트.map((wrongConceptLearning) => ({
        lastElement: 0,
        title: wrongConceptLearning.wrongLearningKey?.concept.name,
      })),
    }

    const 새로운_절차정보 = new ProcessController(this._processInfo).updateProcess(
      오답유형학습단계,
    ).processInfo
    this.patchProcessInfo(this._reference, 새로운_절차정보)
  }

  async onSubmitPairAndSimilarWorksheetProblmes(studentWorksheetId: string) {
    // 전체 마킹한 정보를 onSumbit
    const problemIds = await this.onScoringWrongProblems(studentWorksheetId)
    return problemIds
  }

  async onScoringWrongProblems(studentWorksheetId: string) {
    if (this._오답유형학습_쌍둥이유사_문제풀이_리스트.length > 0) {
      const payload = this._오답유형학습_쌍둥이유사_문제풀이_리스트
        .map((worksheetScoring) => worksheetScoring.problemScoringColl?.toScoredArr)
        .flat() as ProblemScoring<'WORKSHEET'>[]

      if (
        payload &&
        payload.length > 0 &&
        payload[0]?.localUserInput instanceof RequestScoring.자동채점
      ) {
        try {
          await studentWorksheetApi.patchAutoScoringAssignedStudentWorksheet(
            studentWorksheetId,
            payload.map(({ localUserInput }) =>
              RequestScoring.자동채점.toRequestParams<'WORKSHEET', '일반'>(
                localUserInput as RequestScoring.자동채점,
              ),
            ),
          )

          payload?.forEach((item) => {
            localStorageService.removeScoringData({ scoringId: item.id })
          })
        } catch (error: any) {
          if (error?.response?.data?.code === 'STUDENT_WORKSHEET_PROBLEM_DUPLICATE_SUBMIT') {
            toastService.error('이미 제출한 답안은 수정할 수 없습니다.', {
              allowMultipleToast: true,
            })
          }
        }
      }
      return payload?.map((item) => item.id)
    }
  }

  async getWrongLearningListAndUpdateScoring(studentWorksheetId: string) {
    const { data } = await apiSpecFetcher(LearningProcessApi.오답학습하기.spec)({
      params: {
        studentWorksheetId: studentWorksheetId,
      },
    })
    runInAction(() => {
      this._오답유형학습_리스트 = data
    })
    this.updatePairAndSimilarScoring(studentWorksheetId)
  }
}
