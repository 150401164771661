import { isAndroid, isIOS } from 'react-device-detect'

import { localStorageService } from './storage.service'

class WebviewService {
  private static webviewService: WebviewService | null = null

  private constructor(
    private _isAndroid: boolean,
    private _isIOS: boolean,
  ) {}

  static getWebviewService() {
    if (!this.webviewService) {
      this.webviewService = new WebviewService(isAndroid, isIOS)
    }
    return this.webviewService
  }

  get isAndroid() {
    return this._isAndroid
  }

  get isIOS() {
    return this._isIOS
  }

  get isAppVersionUnder5() {
    const ORIENTATION_UNLOCK_APP_VERSION = 5
    const deviceAppVersion = Number(localStorageService.device.ver)

    return deviceAppVersion < ORIENTATION_UNLOCK_APP_VERSION
  }

  openBrowser(url: string) {
    this.sendTo({
      ios: () => window.webkit?.messageHandlers.openBrowser.postMessage(url),
      android: () => window.studentWebView?.openBrowser(url),
    })
  }

  openPdf(url: string, title: string) {
    this.sendTo({
      ios: () => window.webkit?.messageHandlers.openPdf.postMessage(JSON.stringify({ url, title })),
      android: () => window.studentWebView?.openPdf?.(url),
    })
  }

  closeApp() {
    this.sendTo({
      android: () => window.studentWebView?.closeApp?.(),
    })
  }

  logout() {
    this.sendTo({
      ios: () => window.webkit?.messageHandlers.scriptHandler?.postMessage('logout'),
      android: () => window.studentWebView?.logout?.(),
    })
  }

  scanQRCode() {
    this.sendTo({
      ios: () => window.webkit?.messageHandlers.scriptHandler?.postMessage('scanQRCode'),
      android: () => window.studentWebView?.scanQRCode?.(),
    })
  }

  ready() {
    this.sendTo({
      ios: () => window.webkit?.messageHandlers.scriptHandler?.postMessage('ready'),
      android: () => {
        window.studentWebView?.ready?.()
      },
    })
  }

  lockDeviceOrientation() {
    if (this.isAppVersionUnder5) {
      this.sendTo({
        ios: () => window.webkit?.messageHandlers.scriptHandler?.postMessage('lockOrientation'),
        android: () => window.studentWebView?.lockOrientation?.(),
      })
    }
  }

  unlockDeviceOrientation() {
    if (this.isAppVersionUnder5) {
      this.sendTo({
        ios: () => window.webkit?.messageHandlers.scriptHandler?.postMessage('unlockOrientation'),
        android: () => window.studentWebView?.unlockOrientation?.(),
      })
    }
  }

  requestOrientation(orientation: 'portrait' | 'landscape') {
    if (this.isAppVersionUnder5) {
      this.sendTo({
        ios: () =>
          orientation === 'landscape'
            ? window.webkit?.messageHandlers.scriptHandler?.postMessage('requestLandscape')
            : window.webkit?.messageHandlers.scriptHandler?.postMessage('requestPortrait'),
        android: () =>
          orientation === 'landscape'
            ? window.studentWebView?.requestOrientationLandscape()
            : window.studentWebView?.requestOrientationPortrait(),
      })
    }
  }

  private sendTo({ ios, android }: { ios?: () => void; android?: () => void }) {
    if (this._isIOS) {
      ios?.()
    }
    if (this._isAndroid) {
      android?.()
    }
  }
}

export const webviewService = WebviewService.getWebviewService()
