import { s3URL } from '@mathflat/shared/@common/utils/s3'
import MathflatPlayer, { ScreenUtil } from 'mathflat-videoplayer'
import { useEffect } from 'react'

import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'

import GlobalHeader from '../@widgets/(Navigation)/GlobalHeader/GlobalHeader'
import { S } from './GuideVideo.style'

export const GuideVideoPage = () => {
  // 앱의 화면 가로 전환에 대한 락을 풀고, 가로 전환되었을때 이벤트를 감지하여 풀스크린 이벤트 실행.
  useEffect(() => {
    window.addEventListener('onOrientationLandscape', ScreenUtil.handleFullScreen)

    return () => {
      window.removeEventListener('onOrientationLandscape', ScreenUtil.handleFullScreen)
    }
  }, [])

  return (
    <>
      <PortalRootLayoutHeader>
        <GlobalHeader SlotCenter={'사용가이드'} />
      </PortalRootLayoutHeader>
      <S.GuideVideoPage>
        <MathflatPlayer
          ThumbnailElement={
            <S.Thumbnail>
              <img
                src={s3URL.common('images/video/premium-guide/thumbnail.png')}
                style={{ width: '100%', height: '100%' }}
              />
            </S.Thumbnail>
          }
          // CloudFront URL 사용 시 안드로이드 앱에서 간헐적으로 네트워크 발생해서 임시로 URL 변경
          videoUrl="https://mathflat-frontend-assets.s3.ap-northeast-2.amazonaws.com/common/images/video/premium-guide/video.mp4"
          isAutoHideControlLayer={true}
        />
      </S.GuideVideoPage>
    </>
  )
}
