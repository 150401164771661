import type { LearningProcessApi } from '@mathflat/domain/@entities/StudentLearningProcess/api'
import MathflatPlayer from 'mathflat-videoplayer'
import { observer } from 'mobx-react'
import { type FC, useEffect, useRef } from 'react'

import { commonRepo } from '~/@common/services/repo.service'
import { localStorageService } from '~/@common/services/storage.service'

export type RawLecture = LearningProcessApi.개념학습하기.Output['lectures'][number]
export type Lecture = RawLecture['lecture'] & {
  studentLectureCreateDatatime?: string | null
}

interface Props {
  selectedLecture?: Lecture
  onProgressSubmit: () => Promise<void>
}

const LectureVideoPlayer: FC<Props> = ({ selectedLecture, onProgressSubmit }) => {
  const progressSubmittedRef = useRef(false)

  const handleProgress = async (time: number) => {
    if (!selectedLecture) {
      return
    }

    localStorageService.setVideoData('lecture', selectedLecture.id, {
      current: Math.round(time),
    })

    if (progressSubmittedRef.current || !commonRepo.studentId) {
      return
    }
    if (time >= 30) {
      progressSubmittedRef.current = true
      await onProgressSubmit()
    }
  }

  useEffect(() => {
    progressSubmittedRef.current = false
  }, [selectedLecture])

  return (
    <>
      {selectedLecture && (
        <MathflatPlayer
          defaultPlayingTime={
            localStorageService.videoData.lecture.get(selectedLecture.id)?.current
          }
          videoUrl={selectedLecture.videoUrl}
          subtitleUrl={selectedLecture.subtitleUrl ?? undefined}
          ThumbnailElement={
            <div style={{ width: '100%', height: '100%' }}>
              <img src={selectedLecture.thumbnailUrl} style={{ width: '100%', height: '100%' }} />
            </div>
          }
          onEndedCallback={() => {
            localStorageService.removeVideoData('lecture', selectedLecture.id)
          }}
          onProgressCallback={handleProgress}
          isAutoHideControlLayer={true}
        />
      )}
    </>
  )
}

export default observer(LectureVideoPlayer)
