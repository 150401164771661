import type { Entity as ProblemEntity } from '@mathflat/domain/@entities/Problem/dto'
import type { Entity as ScoringEntity } from '@mathflat/domain/@entities/Scoring/dto'
import type { MathflatApi as StudentWorksheetExamApi } from '@mathflat/domain/@entities/StudentExam/api'
import type { MathflatApi as StudentWorkbookApi } from '@mathflat/domain/@entities/StudentWorkbook/api.ts'
import { Entity as StudentWorkbookScoringEntity } from '@mathflat/domain/@entities/StudentWorkbook/StudentWorkbookScoring/dto'
import type { MathflatApi as StudentWorksheetApi } from '@mathflat/domain/@entities/StudentWorksheet/api.ts'
import type { Entity as StudentWorksheetScoringEntity } from '@mathflat/domain/@entities/StudentWorksheet/StudentWorksheetScoring/dto'
import { makeAutoObservable } from 'mobx'

export namespace RequestScoring {
  export class 자동채점<T extends 'WORKSHEET' | 'WORKBOOK' = 'WORKSHEET' | 'WORKBOOK'> {
    private _scoring: T extends 'WORKSHEET'
      ? StudentWorksheetScoringEntity.StudentWorksheetScoring
      : StudentWorkbookScoringEntity.StudentWorkbookScoring

    localUnknown: boolean
    localUserAnswer: ScoringEntity.Scoring['userAnswer']

    constructor(params: {
      localUnknown: boolean
      localUserAnswer: ScoringEntity.Scoring['userAnswer']
      scoring: T extends 'WORKSHEET'
        ? StudentWorksheetScoringEntity.StudentWorksheetScoring
        : StudentWorkbookScoringEntity.StudentWorkbookScoring
    }) {
      makeAutoObservable(this)

      this._scoring = params.scoring
      this.localUnknown = params.localUnknown
      this.localUserAnswer = params.localUserAnswer
    }

    get isNotLocallyScored() {
      if (this.localUnknown) {
        return false
      }
      return !this.localUserAnswer || this.localUserAnswer === ''
    }

    static toRequestParams<
      T extends 'WORKSHEET' | 'WORKBOOK',
      U extends T extends 'WORKSHEET' ? '일반' | '시험지' : never = T extends 'WORKSHEET'
        ? '일반' | '시험지'
        : never,
    >(params: 자동채점<T>): AutoScoringRequest<T, U> {
      // 주관식 답안의 경우 값보정을 해줘야 한다.
      const convertedUserAnswer = 자동채점.toRequestLocalUserAnswer(params)

      const defaultParams = {
        userAnswer: convertedUserAnswer,
        unknown: params.localUnknown,
      }

      if ('deconstructedId' in params._scoring) {
        const _params = {
          ...defaultParams,
          workbookProblemId: params._scoring.deconstructedId.workbookProblemId,
        }
        return _params as AutoScoringRequest<T, U>
      }

      return {
        ...defaultParams,
        [params._scoring.idName]: params._scoring.id,
      } as AutoScoringRequest<T, U>
    }

    private static toRequestLocalUserAnswer(params: 자동채점) {
      if (!params.localUserAnswer) return null

      // TODO: 이 로직 자동채점_주관식쪽으로 가야
      const prefixUnit = _getPrefixOrPostfix({
        type: 'PREFIX',
        scoring: params._scoring,
        localUserAnswer: params.localUserAnswer,
        toType:
          params._scoring instanceof StudentWorkbookScoringEntity.StudentWorkbookScoring
            ? 'KATEX'
            : 'MATHFLAT_TYPE',
      })
      const postfixUnit = _getPrefixOrPostfix({
        type: 'POSTFIX',
        scoring: params._scoring,
        localUserAnswer: params.localUserAnswer,
        toType:
          params._scoring instanceof StudentWorkbookScoringEntity.StudentWorkbookScoring
            ? 'KATEX'
            : 'MATHFLAT_TYPE',
      })

      // 2024.02.07.
      // 자동채점은 시그니처 교재 / 시험지 / 자동채점 학습지에서 사용함
      // 시험지와 자동채점 학습지는 MathflatType을 사용하고 시그니처 교재는 KaTeX을 사용함
      const userAnswer = _formatUserAnswer({
        userAnswer: params.localUserAnswer,
        toType:
          params._scoring instanceof StudentWorkbookScoringEntity.StudentWorkbookScoring
            ? 'KATEX'
            : 'MATHFLAT_TYPE',
      })
      const requestFormattedAnswer = `${prefixUnit}${userAnswer}${postfixUnit}`

      return requestFormattedAnswer
    }
  }

  export class 일반채점<T extends 'WORKSHEET' | 'WORKBOOK' = 'WORKSHEET' | 'WORKBOOK'> {
    localResult: ScoringEntity.Scoring['result']
    private _scoring: T extends 'WORKSHEET'
      ? StudentWorksheetScoringEntity.StudentWorksheetScoring
      : StudentWorkbookScoringEntity.StudentWorkbookScoring

    constructor(params: {
      localResult: ScoringEntity.Scoring['result']
      scoring: T extends 'WORKSHEET'
        ? StudentWorksheetScoringEntity.StudentWorksheetScoring
        : StudentWorkbookScoringEntity.StudentWorkbookScoring
    }) {
      makeAutoObservable(this)

      this.localResult = params.localResult
      this._scoring = params.scoring as T extends 'WORKSHEET'
        ? StudentWorksheetScoringEntity.StudentWorksheetScoring
        : StudentWorkbookScoringEntity.StudentWorkbookScoring
    }

    // 제출하지 않음
    get isNotLocallyScored() {
      return this.localResult === 'NONE'
    }

    static toRequestParams<
      T extends 'WORKSHEET' | 'WORKBOOK',
      U extends T extends 'WORKSHEET' ? '일반' | '시험지' : never = T extends 'WORKSHEET'
        ? '일반' | '시험지'
        : never,
    >(params: 일반채점<T>): ScoringRequest<T, U> {
      const defaultParams = {
        result: params.localResult,
      }
      if ('deconstructedId' in params._scoring) {
        return {
          ...defaultParams,
          ...params._scoring.deconstructedId,
        } as ScoringRequest<T, U>
      }

      return { ...defaultParams, [params._scoring.idName]: params._scoring.id } as ScoringRequest<
        T,
        U
      >
    }
  }
}

const FRAC_REGEX = /\\frac{(-?\w+)}{(-?\w+)}/g

// 사용자 입력값을 mathflatType / KaTeX으로 변경하는 로직
// 현재는 분수가 아니면 두 타입의 모양이 같기 때문에 분수일 때만 format이 일어남
const _formatUserAnswer = ({
  userAnswer,
  toType,
}: {
  userAnswer: string
  toType: 'MATHFLAT_TYPE' | 'KATEX'
}) => {
  const formattedUserAnswer = userAnswer.replace(FRAC_REGEX, (match, numerator, denominator) => {
    // 분자 또는 분모 중 하나만 음수일 경우, -를 앞으로 옮김
    if (
      (numerator.startsWith('-') && !denominator.startsWith('-')) ||
      (!numerator.startsWith('-') && denominator.startsWith('-'))
    ) {
      const absoluteNumerator = Math.abs(numerator)
      const absoluteDenominator = Math.abs(denominator)

      return toType === 'MATHFLAT_TYPE'
        ? `-{${absoluteNumerator}/${absoluteDenominator}}`
        : `-\\frac{${absoluteNumerator}}{${absoluteDenominator}}`
    }

    return toType === 'MATHFLAT_TYPE'
      ? `{${numerator}/${denominator}}`
      : `\\frac{${numerator}}{${denominator}}`
  })

  return formattedUserAnswer
}

const _getRefinedKaTexUnit = (unit: string) => {
  // square brackets 제거하고 space character를 "\; " 형식으로 치환
  const result = unit.replace(/[[\]]/g, '').replace(/\\; /g, ' ').replace(/ /g, '\\; ')

  return result || ''
}

const _getPrefixOrPostfix = ({
  type,
  scoring,
  localUserAnswer,
  toType,
}: {
  type: 'PREFIX' | 'POSTFIX'
  scoring: RequestScoring.자동채점<'WORKSHEET' | 'WORKBOOK'>['_scoring']
  localUserAnswer: RequestScoring.자동채점<'WORKSHEET' | 'WORKBOOK'>['localUserAnswer']
  toType: 'MATHFLAT_TYPE' | 'KATEX'
}) => {
  const findFunc = (item: NonNullable<ProblemEntity.Problem['answerUnits']>[number]) => {
    if (type === 'PREFIX') return item.index === 0
    return item.index !== 0
  }

  const unit = scoring.answerUnits?.find(findFunc)?.unit

  return !unit || !localUserAnswer
    ? ''
    : toType === 'MATHFLAT_TYPE'
      ? unit
      : _getRefinedKaTexUnit(unit)
}

type AutoScoringRequest<
  T extends 'WORKSHEET' | 'WORKBOOK',
  U extends T extends 'WORKSHEET' ? '일반' | '시험지' : never = T extends 'WORKSHEET'
    ? '일반' | '시험지'
    : never,
> = T extends 'WORKBOOK'
  ? StudentWorkbookApi.Request.StudentWorkbookAutoScoringPayload
  : U extends '일반'
    ? StudentWorksheetApi.Request.StudentWorksheetAutoScoring
    : StudentWorksheetExamApi.Request.StudentExamAutoScoring

type ScoringRequest<
  T extends 'WORKSHEET' | 'WORKBOOK',
  U extends T extends 'WORKSHEET' ? '일반' | '시험지' : never = T extends 'WORKSHEET'
    ? '일반' | '시험지'
    : never,
> = T extends 'WORKBOOK'
  ? StudentWorkbookApi.Request.StudentWorkbookScoringPayload
  : U extends '일반'
    ? StudentWorksheetApi.Request.StudentWorksheetScoring
    : never // 시험지는 일반채점 없음
