import 'simplebar-react/dist/simplebar.min.css'

import styled from '@emotion/styled'
import { ANSWER_TYPE } from '@mathflat/domain/@entities/Problem/constants'
import { s3URL } from '@mathflat/shared/@common/utils/s3'
import { observer } from 'mobx-react'
import { useMemo, useState } from 'react'

import { RequestScoring } from '~/@common/api'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { CustomEventService } from '~/@common/services/event.service'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

import { 일반채점입력 } from '../../(Scoring)/(일반채점)/일반채점입력'
import 자동채점입력 from '../../(Scoring)/(자동채점)/자동채점입력'
import type { 자동채점입력_주관식Props } from '../../(Scoring)/(자동채점)/자동채점입력/자동채점입력_주관식'
import { SubmittedAnswer, 채점결과_말풍선 } from '../../(Scoring)/(채점결과)/채점결과'
import type { ProblemScoring } from '../@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '../@trait/ProblemScoringViewOption.trait'
import ProblemScoringStyle from '../ProblemScoringCard/ProblemScoringCard.style'
import { AnswerVideoDrawer } from './_component/AnswerVideoDrawer/AnswerVideoDrawer'
import type { StudentWorkbookDetailService } from '~/@pages/student/student-workbook/detail/@service/StudentWorkbookDetail.service'

export type ProblemScoringFooterProps = {
  problemScoring: ProblemScoring<'WORKSHEET' | 'WORKBOOK'>
  viewOption?: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
  isSubmittedAnswerAvailable?: boolean
  onSubmitVideoAssist?: StudentWorkbookDetailService['onSubmitVideoAssist']
} & Pick<자동채점입력_주관식Props, 'virtualKeybaordShowType'>

const ProblemScoringFooter = observer(
  ({
    problemScoring,
    viewOption,
    virtualKeybaordShowType,
    isSubmittedAnswerAvailable = true,
    onSubmitVideoAssist,
  }: ProblemScoringFooterProps) => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const { isMobile } = useStudentAppMediaQuery()

    if (!viewOption || viewOption.채점불가능) return <></>

    const isMAAT = viewOption.content.type === 'MAAT'

    const 정답해설_공개 =
      !isMAAT &&
      problemScoring.isSubmitted &&
      (!viewOption.studentAppSetting || viewOption.studentAppSetting.채점후정답해설공개)

    const 문풀동공개 =
      !isMAAT &&
      problemScoring.isSubmitted &&
      (!viewOption.studentAppSetting || viewOption.studentAppSetting?.채점후문풀동공개)

    const 해설내풀이문구_버튼명 = useMemo(() => {
      return (
        <>
          {문풀동공개 && <span className="explation-submitted-answer-text">풀이동영상</span>}
          {isSubmittedAnswerAvailable && (
            <span className="explation-submitted-answer-text">필기</span>
          )}
          {정답해설_공개 && !!problemScoring.문제해설이미지 && (
            <span className="explation-submitted-answer-text">해설</span>
          )}
        </>
      )
    }, [문풀동공개, 정답해설_공개, problemScoring.문제해설이미지, isSubmittedAnswerAvailable])

    // 제출 후
    if (problemScoring.isSubmitted) {
      // 자기주도가 아닐 때
      if (viewOption.studentAppSetting) {
        if (
          (!isSubmittedAnswerAvailable &&
            !viewOption.studentAppSetting.채점후정답해설공개 &&
            !viewOption.studentAppSetting.채점후문풀동공개) ||
          !problemScoring.문제해설이미지
        ) {
          return <></>
        }
      }

      return (
        <>
          <S.ProblemScoringFooter
            data-tooltip-id={`submitted-answer-${problemScoring.id}`}
            onClick={(e) => {
              setOpenDrawer((prev) => !prev)
            }}
            className="has-padding"
          >
            {!!problemScoring.제출한답 && (
              <span
                className="left"
                onClick={(e) => {
                  e.stopPropagation()
                  if (isMobile) {
                    modalService.openModal(
                      <ProblemScoringStyle.MobileModalContainer>
                        <SubmittedAnswer
                          채점결과={problemScoring.채점결과}
                          제출한답={problemScoring.제출한답}
                          문제정답타입={problemScoring.문제정답타입}
                          ellipse={false}
                        />
                      </ProblemScoringStyle.MobileModalContainer>,
                      { modalName: '제출한 답 모달', hasCloseButton: true },
                    )
                  } else {
                    CustomEventService.tooltipOn.dispatch(
                      `submitted-answer-${problemScoring.id}`,
                      e,
                    )
                  }
                }}
              >
                <span className="answer-text">내가 쓴 답 :&nbsp;</span>

                <채점결과_말풍선
                  id={`submitted-answer-${problemScoring.id}`}
                  value={problemScoring.채점결과}
                  문제정답타입={problemScoring.문제정답타입}
                  제출한답={problemScoring.제출한답}
                />
                <SubmittedAnswer
                  채점결과={problemScoring.채점결과}
                  제출한답={problemScoring.제출한답}
                  문제정답타입={problemScoring.문제정답타입}
                />
              </span>
            )}
            {problemScoring.isAutoScoring && problemScoring.제출한답 === null && (
              <div className="left">
                <span className="answer-guide">선생님이 채점함</span>
              </div>
            )}

            {viewOption.content.type !== 'CONCEPTUAL' && (
              <span className="right">
                {해설내풀이문구_버튼명}
                <Icon name="icon_chevron_right" color={colors.gray.$500} size={20} />
              </span>
            )}
            <AnswerVideoDrawer
              isSubmittedAnswerAvailable={isSubmittedAnswerAvailable}
              viewOption={viewOption}
              openDrawer={openDrawer}
              problemScoring={problemScoring}
              onSubmitVideoAssist={onSubmitVideoAssist}
            />
          </S.ProblemScoringFooter>
        </>
      )
    }

    // 문제 : 제출 전
    // 학습지 or 교재 : 채점 가능 상태
    if (!viewOption.채점불가능) {
      // 1. 자동채점
      // 1-1. 주관식
      if (problemScoring.localUserInput instanceof RequestScoring.자동채점) {
        if (problemScoring.문제정답타입 === 'SHORT_ANSWER') {
          const virtualKeyboardMarginBottom =
            isMobile && viewOption.content.type === 'CONCEPTUAL' ? 10 : undefined

          return (
            <S.ProblemScoringFooter className="has-padding">
              <자동채점입력.주관식
                id={problemScoring.id}
                keypadTypes={problemScoring.keypadTypes}
                answerUnits={problemScoring.answerUnits}
                value={problemScoring.localUserInput.localUserAnswer ?? ''}
                onChange={(v) => {
                  problemScoring.setLocalUserInput(v)
                }}
                virtualKeybaordShowType={virtualKeybaordShowType}
                virtualKeyboardMarginBottom={virtualKeyboardMarginBottom}
              />
            </S.ProblemScoringFooter>
          )
        }

        if (problemScoring.문제n지선다) {
          // 1-2. 객관식
          const data = Array.from({ length: problemScoring.문제n지선다 }).map((_, index) => ({
            label: index + 1,
            value: index + 1,
          }))

          return (
            <S.ProblemScoringFooter className="has-padding">
              <자동채점입력.객관식
                circleClassName={viewOption.content.type === 'CONCEPTUAL' ? 'circle-sm' : undefined}
                data={data}
                values={
                  problemScoring.localUserInput.localUserAnswer === ''
                    ? []
                    : problemScoring.localUserInput.localUserAnswer?.split(',') ?? []
                }
                max={problemScoring.문제정답길이}
                onChange={(v) => {
                  problemScoring.setLocalUserInput(v.join(','))
                }}
              />
            </S.ProblemScoringFooter>
          )
        } else {
          console.error('문제n지선다 값이 없습니다.')
          return null
        }
      }

      // 2. 일반 채점
      return (
        <S.ProblemScoringFooter>
          <일반채점입력
            value={problemScoring.localUserInput?.localResult ?? ANSWER_TYPE.NONE}
            onChange={(v) => {
              problemScoring.setLocalUserInput(v)
            }}
          />
        </S.ProblemScoringFooter>
      )
    }
  },
)

const S = {
  ProblemScoringFooter: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 70px;
    background-color: white;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    ${typo.body02};

    > * {
      cursor: pointer;
    }
    &.has-padding {
      padding: 0 20px;
    }

    > .left,
    > .center {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;

      font-weight: bold;
      color: ${colors.gray.$700};
      .answer-text {
        flex-shrink: 0;
      }

      .answer-guide {
        color: ${colors.gray.$600};
        font-weight: 400;
        ${typo.body01}
      }
    }

    > .center {
      justify-content: center;
    }

    > .right {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 36px;
      margin: 0 auto;
      cursor: pointer;

      color: ${colors.gray.$600};
    }
    .explation-submitted-answer-text + .explation-submitted-answer-text {
      margin-left: 4px;
      &::before {
        content: ' ・ ';
      }
    }
  `,
  Thumbnail: styled.div`
    width: 100%;
    height: 100%;
    background: url(${s3URL.common('images/video/problem-solving/background.png')}) #fff;

    > .img-bg {
      position: absolute;
      top: 7%;
      left: 30%;
      padding: 4% 4% 0px 4%;

      width: 64%;
      height: 93%;
      background: #fff;
      box-shadow: 0px 4px 30px 0px #0000001a;
    }

    > .gradation {
      position: absolute;
      top: 50%;

      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(251, 252, 255, 0) 0%, #fbfcff 72.4%);

      > .title {
        position: absolute;
        top: 40%;
        left: 7%;
      }
      > .sub-title {
        position: absolute;
        top: 20%;
        left: 7%;
      }
    }
  `,
}

export default ProblemScoringFooter
