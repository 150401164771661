import * as S from '@effect/schema/Schema'
import { ISODateString } from '@mathflat/shared/@common/schema/schema'
import { apiSpec } from '@mathflat/shared/@modules/ApiSpec/apiSpec.ts'
import type { ApiSpecInputParams, ApiSpecOutputData } from '@mathflat/shared/@modules/ApiSpec/types'

import { CommonDomain, LearningProcessDomain, WorksheetDomain } from '..'
import type { Entity as WorksheetEntity } from '../(Content)/Worksheet/dto'
import type { Entity as WorksheetProblemEntity } from '../(Content)/Worksheet/WorksheetProblem/dto'
import { AllGrade, SchoolType } from '../(SchoolSystem)/schema'
import type { Entity as HomeworkEntity } from '../Homework/dto'
import type { Entity as ProblemEntity } from '../Problem/dto'
import type { Entity as ScoringEntity } from '../Scoring/dto'
import type {
  Entity as StudentWorksheetScoringEntity,
  StudentWorksheetScoringParams,
} from '../StudentWorksheet/StudentWorksheetScoring/dto'
import type { Entity as StudentWorksheetEntity } from './dto'

const AssignedWorksheetInfo = S.struct({
  assignDatetime: ISODateString,
  assignList: S.array(LearningProcessDomain.AssignedWorksheet),
})

export module StudentWorksheetApi {
  const _ReferenceType = S.literal('CONCEPT_CHIP', 'LITTLE_CHAPTER')

  /**
   * 보충_오답학습지생성 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-33fd2b07-7406-4b7b-81bf-a9baea1e7b34?ctx=documentation
   */
  export module 보충_오답학습지생성 {
    export const spec = apiSpec({
      method: 'POST',
      path: '/student-learning/self-learning/wrong',
      params: S.struct({ studentWorksheetId: S.number }),
      responseData: AssignedWorksheetInfo,
    })
    export type RouteParams = ApiSpecInputParams<typeof spec>
    export type Output = ApiSpecOutputData<typeof spec>
  }

  /**
   * 보충_심화학습지생성 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-e5127b78-8032-4a5c-9f50-d184b6c320cf?ctx=documentation
   */
  export module 보충_심화학습지생성 {
    export const spec = apiSpec({
      method: 'POST',
      path: '/student-learning/self-learning/level-up',
      params: S.struct({ studentWorksheetId: S.number }),
      responseData: AssignedWorksheetInfo,
    })
    export type RouteParams = ApiSpecInputParams<typeof spec>
    export type Output = ApiSpecOutputData<typeof spec>
  }

  /**
   * 챌린지학습지조회 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-1e4bef1e-7167-437c-b8ae-ece8f46b8d75?ctx=documentation
   */
  export module 챌린지학습지조회 {
    export const ReferenceType = _ReferenceType
    export type ReferenceType = S.Schema.From<typeof ReferenceType>
    export const Worksheet = S.struct({
      id: S.number,
      type: WorksheetDomain.Type,
      school: SchoolType,
      grade: AllGrade,
      title: S.string,
      titleTag: S.string,
      chapter: S.string,
      tag: WorksheetDomain.Tag,
      problemCount: S.number,
      level: WorksheetDomain.Level,
      autoScorable: S.boolean,
      accessModifierToStudent: WorksheetDomain.AccessModifierToStudent,
    })

    export const spec = apiSpec({
      method: 'GET',
      path: '/student-learning/self-learning/challenge/last',
      params: S.struct({
        referenceType: ReferenceType,
        referenceId: S.number,
        conceptChipIds: S.array(S.number),
        littleChapterId: S.number,
      }),
      responseData: S.struct({
        id: S.number,
        assignDatetime: ISODateString,
        openDatetime: ISODateString.pipe(S.nullable),
        scoreDatetime: ISODateString.pipe(S.nullable),
        score: S.number,
        status: CommonDomain.Status,
        learningStep: S.string,
        worksheet: Worksheet,
      }).pipe(S.nullable),
    })

    export type Params = ApiSpecInputParams<typeof spec>
    export type Output = ApiSpecOutputData<typeof spec>
  }

  /**
   * 챌린지학습지생성 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-603006ab-d045-4c88-acdf-03ca1677ef5e?ctx=documentation
   */
  export module 챌린지학습지생성 {
    export const ReferenceType = _ReferenceType

    export const spec = apiSpec({
      method: 'POST',
      path: '/student-learning/self-learning/challenge',
      params: S.struct({
        referenceType: ReferenceType,
        referenceId: S.number,
        conceptChipIds: S.array(S.number),
        littleChapterId: S.number,
      }),
      responseData: AssignedWorksheetInfo,
    })

    export type Params = ApiSpecInputParams<typeof spec>
    export type Output = ApiSpecOutputData<typeof spec>
  }

  export module 챌린지학습지_상태변경 {
    export const ReferenceType = _ReferenceType

    export const spec = apiSpec({
      method: 'PATCH',
      path: 'student-learning/self-learning/challenge',
      params: S.struct({
        studentWorksheetId: S.number,
        learningStep: S.string,
      }),
    })

    export type Params = ApiSpecInputParams<typeof spec>
  }
}
export namespace MathflatApi {
  export namespace Request {
    export type StudentWorksheetValidation = {
      worksheetId: string
      dateTime: string
    }
    export type StudentWorksheetAutoScoring = {
      worksheetProblemId: ScoringEntity.Scoring<'WORKSHEET'>['id']
      userAnswer: ScoringEntity.Scoring<'WORKSHEET'>['userAnswer']
      unknown: boolean
    }[]
    export type StudentWorksheetScoring = {
      worksheetProblemId: number
      result: ScoringEntity.Scoring<'WORKSHEET'>['result']
    }
    export type StudentWorksheetWithProblemsParams = {
      answerHideFlag?: boolean
    }
  }
  export namespace Response {
    type WorksheetProblem = {
      problem: ProblemEntity.Problem
      result: 'NONE'
      userAnswer: ''
      worksheetProblemId: number
    }
    export type StudentWorksheetScoring = {
      problem: ProblemEntity.Problem
      worksheetProblemId: WorksheetProblemEntity.CustomWorksheetProblem['id']
      handwrittenNoteUrl: string | null
    } & Omit<StudentWorksheetScoringEntity.StudentWorksheetScoring, 'updateDatetime'> &
      Pick<StudentWorksheetScoringParams['scoring'], 'updateDatetime'>

    export type WorksheetWithProblems = {
      status: 'INCOMPLETE' | 'COMPLETE'
      autoScorable: boolean
      worksheetProblems: WorksheetProblem[]
    }
    export type WorksheetAndStudentWorksheet = StudentWorksheetEntity.StudentWorksheet & {
      worksheet: WorksheetEntity.Worksheet
    }
    export type WorksheetAndStudentWorksheetWithHomeworks = WorksheetAndStudentWorksheet & {
      homeworks: HomeworkEntity.Homework[]
      selfLearningStudentWorksheets: SelfLearningWorksheetAndStudentWorksheet[]
    }
    export type SelfLearningWorksheetAndStudentWorksheet =
      StudentWorksheetEntity.StudentWorksheet & {
        worksheet: WorksheetEntity.LevelUpWorksheet | WorksheetEntity.WrongConceptWorksheet
      }
    export type StudentWorksheetValidation = {
      studentWorksheetId: string | null
      worksheetState: 'NOT_ASSIGN' | 'ASSIGN' | 'UNAVAILABLE' | 'AVAILABLE' | 'DENIED'
    }
    export type SelfLearningStudentWorksheets = {
      prevStudentWorksheetAndWorksheet: StudentWorksheetEntity.StudentWorksheet & {
        worksheet: WorksheetEntity.Worksheet
      }
      nextStudentWorksheetAndWorksheets: SelfLearningWorksheetAndStudentWorksheet[]
    }
  }
}
