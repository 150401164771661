import { toastService } from '~/@common/services'

class ReadableStreamReaderUtils {
  async addMessageListener({
    reader,
    onMessage,
    onDone,
  }: {
    reader: ReadableStreamDefaultReader<string>
    onMessage: (value: string) => void
    onDone: () => void
  }) {
    let buffer = ''

    while (true) {
      const { value, done } = await reader.read()
      if (done) break
      buffer += value

      const messages = buffer.split('\n').filter((line) => line.startsWith('data:'))

      for (const message of messages) {
        try {
          const isDone = message.slice(5).trim() === '[DONE]'

          if (isDone) {
            onDone()
            return
          }

          const data = JSON.parse(message.slice(5))

          if (data && data.delta) {
            onMessage(data.delta.content[0].text.value)
          }
        } catch (error) {
          toastService.error('다시 시도해주세요.')
        }
      }
      buffer = buffer.slice(buffer.lastIndexOf('\n') + 1)
    }
  }
}

export const readableStreamReaderUtils = new ReadableStreamReaderUtils()
