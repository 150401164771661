import 'simplebar-react/dist/simplebar.min.css'

import styled from '@emotion/styled'
import { type HandwrittenNoteTypes, ReadOnlyHandwrittenNote } from '@mathflat/handwritten-note'
import { s3URL } from '@mathflat/shared/@common/utils/s3'
import { clsx } from 'clsx'
import MathflatPlayer from 'mathflat-videoplayer'
import { useEffect, useState } from 'react'
import SimpleBar from 'simplebar-react'

import { handwrittenNoteApi } from '~/@common/api/handwrittenNoteApi'
import { errorHandlerService } from '~/@common/services'
import { colors, typo } from '~/@common/styles'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { Icon } from '~/@common/ui/Icon/Icon'

interface Props {
  isOpened: boolean
  videoUrl?: string | null
  problemImageUrl?: string
  problemExplanationImageUrl?: string
  handwrittenNoteUrl?: string | null
  onCloseDrawer?: () => void
}

const ProblemScoringResultTableDrawer = ({
  isOpened,
  videoUrl,
  problemImageUrl,
  problemExplanationImageUrl,
  handwrittenNoteUrl,
  onCloseDrawer,
}: Props) => {
  const [isHiddenNoteData, setIsHiddenNoteData] = useState(false)
  const [noteData, setNoteData] = useState<HandwrittenNoteTypes.NoteData>()

  useEffect(() => {
    if (handwrittenNoteUrl) {
      handwrittenNoteApi
        .fetchNoteByUrl(handwrittenNoteUrl)
        .then((data) => {
          setNoteData(data)
        })
        .catch(errorHandlerService.handle)
    } else {
      setNoteData(undefined)
    }
  }, [handwrittenNoteUrl])

  const handleToggleHiddenNoteData = () => {
    setIsHiddenNoteData((prev) => !prev)
  }

  const handleCloseDrawer = () => {
    setIsHiddenNoteData(false)
    onCloseDrawer?.()
  }

  return (
    <Drawer
      isOpened={isOpened}
      placement="left"
      rootSelector="#app"
      hasBorderRadius={false}
      size="730px"
      closeDrawer={handleCloseDrawer}
      backdropOpacity={0}
      position="absolute"
    >
      <Drawer.Content
        style={{
          height: '100%',
          boxShadow: '10px 4px 20px 0px rgba(0, 0, 0, 0.05)',
          marginTop: '0',
        }}
      >
        <Drawer.Header style={{ borderTop: `1px solid ${colors.gray.$300}`, borderRadius: 0 }}>
          풀이동영상 ・ 문제&내 풀이 ・ 해설
        </Drawer.Header>
        <Drawer.Body>
          <S.DrawerBody>
            <>
              <p>풀이동영상</p>
              {videoUrl ? (
                <MathflatPlayer
                  ThumbnailElement={
                    <S.Thumbnail>
                      <div className="img-bg">
                        {problemImageUrl && (
                          <img src={problemImageUrl} style={{ width: '100%', height: '100%' }} />
                        )}
                      </div>
                      <div className="gradation">
                        <img
                          className="sub-title"
                          src={s3URL.common(
                            'images/video/problem-solving/sub-title_student-app@1x.png',
                          )}
                          srcSet={`${s3URL.common(
                            'images/video/problem-solving/sub-title_student-app@2x.png',
                          )} 2x, ${s3URL.common('images/video/problem-solving/sub-title_student-app@3x.png')} 3x`}
                        />
                        <img
                          className="title"
                          src={s3URL.common('images/video/problem-solving/title@1x.png')}
                          srcSet={`${s3URL.common(
                            'images/video/problem-solving/title@2x.png',
                          )} 2x, ${s3URL.common('images/video/problem-solving/title@3x.png')} 3x`}
                        />
                      </div>
                    </S.Thumbnail>
                  }
                  videoUrl={videoUrl}
                  isAutoHideControlLayer={true}
                />
              ) : (
                <div className="no-video">풀이동영상이 없어요</div>
              )}

              <div className="divider" />
            </>
            <div className="body-header">
              <p>문제 ・ 내 풀이</p>
              {noteData ? (
                <button onClick={handleToggleHiddenNoteData} className="icon-button">
                  <Icon
                    name={isHiddenNoteData ? 'icon_eye_closed' : 'icon_eye_opened'}
                    size={22}
                    color={colors.gray.$800}
                  />
                </button>
              ) : (
                <p className="no-writing">필기한 기록이 없어요</p>
              )}
            </div>

            {noteData && problemImageUrl && handwrittenNoteUrl && !isHiddenNoteData ? (
              <SimpleBar style={{ maxHeight: 312 }} key="with-note-data">
                <div className={clsx('문제및풀이', noteData && 'has-note-data')}>
                  <ReadOnlyHandwrittenNote
                    noteData={noteData}
                    isHidden={isHiddenNoteData}
                    preventScale
                    preventOverflow
                  >
                    <img src={problemImageUrl} alt="문제 이미지" width={344} />
                  </ReadOnlyHandwrittenNote>
                </div>
              </SimpleBar>
            ) : (
              <SimpleBar style={{ maxHeight: 312 }} key="without-note-data">
                <div className={clsx('문제및풀이', noteData && 'has-note-data')}>
                  <img src={problemImageUrl} alt="문제 이미지" width={344} />
                </div>
              </SimpleBar>
            )}

            {problemExplanationImageUrl && (
              <>
                <div className="divider" />
                <p>해설</p>
                <img src={problemExplanationImageUrl} width={344} />
              </>
            )}
          </S.DrawerBody>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer>
  )
}

export default ProblemScoringResultTableDrawer

const S = {
  DrawerBody: styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px 40px 20px;
    width: 100%;
    overflow: auto;
    gap: 20px;
    min-height: 100%;

    p {
      color: ${colors.gray.$900};
      font-weight: 700;
    }

    .divider {
      height: 1px;
      background-color: ${colors.gray.$300};
      width: 100%;
    }
    .no-video {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 40px 0;
      ${typo.body02};
      color: ${colors.gray.$700};
      font-weight: 400;
    }

    .no-writing {
      ${typo.body02};
      color: ${colors.gray.$800};
      font-weight: 400;
    }

    .body-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: -12px;
      height: 48px;

      .icon-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
      }
    }

    .문제및풀이 {
      max-height: 312px;

      &.has-note-data {
        min-height: 312px;
      }

      > div {
        width: fit-content;
      }
    }
  `,

  Thumbnail: styled.div`
    width: 100%;
    height: 100%;
    background: url(${s3URL.common('images/video/problem-solving/background.png')}) #fff;

    > .img-bg {
      position: absolute;
      top: 7%;
      left: 30%;
      padding: 4% 4% 0px 4%;

      width: 64%;
      height: 93%;
      background: #fff;
      box-shadow: 0px 4px 30px 0px #0000001a;
    }

    > .gradation {
      position: absolute;
      top: 50%;

      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(251, 252, 255, 0) 0%, #fbfcff 72.4%);

      > .title {
        position: absolute;
        top: 40%;
        left: 7%;
      }
      > .sub-title {
        position: absolute;
        top: 20%;
        left: 7%;
      }
    }
  `,
}
