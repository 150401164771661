import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { useRepository } from '~/@common/hooks/useRepository'
import { commonRepo } from '~/@common/services/repo.service'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import GroupBox from '~/@pages/@common/(LectureMaterial)/GroupBox'
import LectureList from '~/@pages/@common/(LectureMaterial)/LectureList'
import type { Lecture } from '~/@pages/@common/(LectureMaterial)/LectureVideoItem'
import LectureVideoPlayer from '~/@pages/@common/(LectureMaterial)/LectureVideoPlayer'
import NoData from '~/@pages/@common/(LectureMaterial)/NoData'
import { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service'

interface Props {
  lectures: Lecture[]
  prevLectures: Lecture[]
}

const LectureMaterialSection: FC<Props> = ({ lectures, prevLectures }) => {
  const service = useRepository(LearningProcessService)
  const [selectedLecture, setSelectedLecture] = useState<Lecture>()
  const hasLectures = lectures.length > 0
  const hasPrevLectures = prevLectures.length > 0

  const handleProgressSubmit = async () => {
    if (selectedLecture && commonRepo.studentId) {
      service.postVideo({ lectureId: selectedLecture.id, studentId: commonRepo.studentId })
    }
  }

  const handleLectureClick = (item: Lecture) => {
    if (item.id === selectedLecture?.id) {
      return
    }
    setSelectedLecture(item)
  }

  useEffect(() => {
    if (!hasLectures && !hasPrevLectures) {
      setSelectedLecture(undefined)
      return
    }

    setSelectedLecture(lectures[0] ?? prevLectures[0])
  }, [hasLectures, hasPrevLectures, lectures, prevLectures])

  return (
    <div css={_css}>
      {hasLectures || hasPrevLectures ? (
        <div className="video-section">
          <div className="video">
            {selectedLecture && (
              <LectureVideoPlayer
                selectedLecture={selectedLecture}
                onProgressSubmit={handleProgressSubmit}
              />
            )}
          </div>
          <div className="video-selectors">
            <div className="video-list-section">
              <GroupBox type="lectures" isPreLearned={false} isDefaultOpened={true}>
                {hasLectures ? (
                  <LectureList
                    items={lectures}
                    selectedItem={selectedLecture}
                    isMobile={isMobile}
                    onLectureClick={handleLectureClick}
                  />
                ) : (
                  <NoData
                    iconSize={isMobile ? 'small' : 'normal'}
                    content="영상이 아직 준비되지 않았어요."
                  />
                )}
              </GroupBox>

              <GroupBox
                type="lectures"
                isPreLearned={true}
                isDefaultOpened={!hasLectures && hasPrevLectures}
              >
                {hasPrevLectures ? (
                  <LectureList
                    items={prevLectures}
                    selectedItem={selectedLecture}
                    isMobile={isMobile}
                    onLectureClick={handleLectureClick}
                  />
                ) : (
                  <NoData
                    iconSize={isMobile ? 'small' : 'normal'}
                    content="영상이 아직 준비되지 않았어요."
                  />
                )}
              </GroupBox>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty-video-section">
          <div className="empty-notice">
            <NoData
              iconSize="normal"
              content={
                <>
                  개념 영상이 아직 준비되지 않았어요.
                  <br />
                  유형내용을 읽어보고 문제를 풀어보세요.
                </>
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(LectureMaterialSection)

const _css = css`
  display: flex;
  width: 100%;

  .video-section {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    ${mediaQuery.underTablet} {
      height: fit-content;
      overflow: visible;
    }
  }

  .video {
    width: 100%;
  }

  .video-selectors {
    margin-top: 12px;

    ${mediaQuery.underTablet} {
      padding: 20px 16px 0 16px;
    }
  }

  .video-list-section {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .empty-video-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    ${mediaQuery.underTablet} {
      padding: 0 16px;
    }

    .empty-notice {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`
