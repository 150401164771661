import { css } from '@emotion/react'

import { colors, colorTheme, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

interface LearningProgressProps {
  learningProgress: number
}

export const LearningProgress = ({ learningProgress: 진척도 }: LearningProgressProps) => {
  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        gap: 4px;
        color: ${colors.gray.$700};
        ${typo.caption01}
        white-space: nowrap;
      `}
    >
      진척도
      <div
        css={css`
          display: flex;
          align-items: center;
          width: 176px;
          height: 8px;
          border-radius: 100px;
          background: ${colors.gray.$300};
          ${mediaQuery.underTablet} {
            width: 100%;
          }
        `}
      >
        <div
          css={[
            css`
              height: 100%;
              background: ${colorTheme.primary};
              color: ${colorTheme.primary};
              border-radius: 100px;
              width: ${진척도}%;

              &.out-dated {
                background: var(--NewColors-Gray-500, #c0c0c0);
              }
            `,
          ]}
        />
        <span
          css={css`
            color: ${colors.gray.$700};
            ${typo.caption01}
          `}
        ></span>
      </div>
      {진척도}%
    </span>
  )
}
