import styled from '@emotion/styled'
import { s3URL } from '@mathflat/shared/@common/utils/s3'
import MathflatPlayer from 'mathflat-videoplayer'
import { observer } from 'mobx-react'
import type { MouseEvent } from 'react'

import { useRepository } from '~/@common/hooks/useRepository'
import { localStorageService } from '~/@common/services/storage.service'
import { mediaQuery } from '~/@common/styles/mediaQuery'

import { LearningProcessService } from '../../../../service/LearningProcess.service'

const WrongLearningVideo = observer(() => {
  const service = useRepository(LearningProcessService)

  const handleVideoContainerClick = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement | null

    if (!target) {
      return
    }

    if (target.classList.contains('setting-button') || target.closest('.setting-button')) {
      e.stopPropagation()
    }
  }

  if (
    service.오답유형학습_문제풀이동영상.problem &&
    service.오답유형학습_문제풀이동영상.problem.video
  ) {
    return (
      <S.Video onClick={handleVideoContainerClick}>
        <MathflatPlayer
          defaultPlayingTime={
            localStorageService.videoData['problem-solving-video'].get(
              service.오답유형학습_문제풀이동영상.problem.video.id,
            )?.current
          }
          onProgressCallback={(time) => {
            if (service.오답유형학습_문제풀이동영상.problem?.video?.id) {
              localStorageService.setVideoData(
                'problem-solving-video',
                service.오답유형학습_문제풀이동영상.problem.video.id,
                {
                  current: Math.round(time),
                },
              )
            }
          }}
          onEndedCallback={() => {
            if (service.오답유형학습_문제풀이동영상.problem?.video?.id) {
              localStorageService.removeVideoData(
                'problem-solving-video',
                service.오답유형학습_문제풀이동영상.problem.video.id,
              )
            }
          }}
          videoUrl={service.오답유형학습_문제풀이동영상.problem.video.videoUrl}
          ThumbnailElement={
            <S.Thumbnail>
              <div className="img-bg">
                {service.오답유형학습_문제풀이동영상.problem?.problemImageUrl && (
                  <img
                    src={service.오답유형학습_문제풀이동영상.problem.problemImageUrl}
                    style={{ width: '100%', height: '100%' }}
                  />
                )}
              </div>
              <div className="gradation">
                <img
                  className="sub-title"
                  src={s3URL.common('images/video/problem-solving/sub-title_student-app@1x.png')}
                  srcSet={`${s3URL.common(
                    'images/video/problem-solving/sub-title_student-app@2x.png',
                  )} 2x, ${s3URL.common('images/video/problem-solving/sub-title_student-app@3x.png')} 3x`}
                />
                <img
                  className="title"
                  src={s3URL.common('images/video/problem-solving/title@1x.png')}
                  srcSet={`${s3URL.common(
                    'images/video/problem-solving/title@2x.png',
                  )} 2x, ${s3URL.common('images/video/problem-solving/title@3x.png')} 3x`}
                />
              </div>
            </S.Thumbnail>
          }
          isAutoHideControlLayer={true}
        />
      </S.Video>
    )
  } else {
    return (
      <S.Empty>
        <img
          src={`/images/icons/icon_balloon_dot.svg`}
          width={80}
          height={80}
          alt="문제풀이영상없음"
        />
        문제 풀이 영상이 아직 준비되지 않았어요.
        <br />
        틀린 문제를 확인해보고 문제를 풀어보세요.
      </S.Empty>
    )
  }
})

export default WrongLearningVideo

const S = {
  Video: styled.div`
    width: 486px;
    min-width: 384px;
    height: fit-content;
    ${mediaQuery.underTablet} {
      width: 100%;
      margin-right: 0;
    }
  `,
  Thumbnail: styled.div`
    width: 100%;
    height: 100%;
    background: url(${s3URL.common('images/video/problem-solving/background.png')}) #fff;

    > .img-bg {
      position: absolute;
      top: 7%;
      left: 30%;
      padding: 4% 4% 0px 4%;

      width: 64%;
      height: 93%;
      background: #fff;
      box-shadow: 0px 4px 30px 0px #0000001a;
    }

    > .gradation {
      position: absolute;
      top: 50%;

      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(251, 252, 255, 0) 0%, #fbfcff 72.4%);

      > .title {
        position: absolute;
        top: 40%;
        left: 7%;
      }
      > .sub-title {
        position: absolute;
        top: 20%;
        left: 7%;
      }
    }
  `,
  Empty: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 486px;
    height: 100%;
    min-width: 384px;

    ${mediaQuery.underTablet} {
      width: 100%;
      min-width: 100%;
      height: 230px;
    }
  `,
}
