import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill'

import chatAxios, { fetchSSE } from '~/@common/utils/chatAxios.ts'

const EventSource = EventSourcePolyfill || NativeEventSource

type AiTutorParams = {
  sessionId: string
  userHandwrittenNoteUrl: string
}

export const chatApi = (() => {
  return {
    getSessionId: async () => {
      const {
        data: { sessionId },
      } = await chatAxios.post('/ai/session')
      return sessionId
    },

    getHintConceptual: async (
      problemId: number,
      params: Omit<AiTutorParams, 'userHandwrittenNoteUrl'>,
    ) => {
      return fetchSSE(`/ai/hint-conceptual/${problemId}`, {
        params: {
          sessionId: params.sessionId,
        },
      })
    },
    getSolveTheStuckUp: async (problemId: number, params: AiTutorParams) => {
      return fetchSSE(`/ai/solve-the-stuck-up/${problemId}`, {
        params: {
          sessionId: params.sessionId,
          userHandwrittenNoteUrl: params.userHandwrittenNoteUrl,
        },
      })
    },

    getReviewTheSolution: (problemId: number, params: AiTutorParams) => {
      return fetchSSE(`/ai/review-the-solution/${problemId}`, {
        params: {
          sessionId: params.sessionId,
          userHandwrittenNoteUrl: params.userHandwrittenNoteUrl,
        },
      })
    },
  }
})()
