import axios from 'axios'
import { makeAutoObservable } from 'mobx'
import qs from 'qs'

import { envUtils } from '~/@common/utils/envUtils'

const BASE_URL = (() => {
  if (envUtils.isLive) {
    return 'https://remote-storage.mathflat.com'
  }
  if (envUtils.isStaging) {
    return 'https://remote-storage-staging.mathflat.com'
  }

  return 'https://remote-storage-dev.mathflat.com'
})()

const remoteStorageAxios = axios.create({
  baseURL: BASE_URL,
  paramsSerializer: (params) => {
    return qs.stringify(params)
  },
})

type RemoteStorageItem = {
  curriculumKey: string | null
  isVisitedSelfLearning: boolean
  isCompletedSelfLearning: boolean
}

class RemoteStorageService implements RemoteStorageItem {
  private relationId: string | null = null
  curriculumKey: string | null = null
  isVisitedSelfLearning: boolean = false
  isCompletedSelfLearning: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  get isReady() {
    return !!this.relationId
  }

  initRelationId = (relationId) => {
    if (!relationId) return

    this.relationId = relationId
  }

  get = async (...keys: string[]) => {
    if (!this.isReady) {
      return
    }

    const { data } = await remoteStorageAxios.get(`/storage/${this.relationId}`, {
      params: {
        keys: keys.join(','),
      },
    })

    for (const [k, v] of Object.entries(data)) {
      this._set(k, v)
    }

    // return data as Record<K, RemoteStorageItem[K]>
    return data
  }

  // ** 주의 **
  // write 후에 바로 최신 데이터 read가 필요한 경우에만 사용해야 함.
  // 정확한 최신 데이터를 보장할 수 있으나 조회 비용이 높음.(2배)
  //
  // consistentGet = async (...keys: string[]) => {
  //   if (!this.isReady) {
  //     return
  //   }

  //   const { data } = await remoteStorageAxios.get(
  //     `/storage/${this.relationId}?consistentRead=true`,
  //     {
  //       params: {
  //         keys: keys.join(','),
  //       },
  //     },
  //   )

  //   for (const [k, v] of Object.entries(data)) {
  //     this._set(k, v)
  //   }

  //   // return data as Record<K, RemoteStorageItem[K]>
  //   return data
  // }

  private _set = (k: string, v: any) => {
    this[k] = v
  }

  patch = async (k: string, v: any) => {
    if (!this.isReady) {
      return
    }

    remoteStorageAxios.patch(`/storage/${this.relationId}`, { [k]: v }).then(() => {
      this._set(k, v)
    })
  }
}

export const remoteStorageService = new RemoteStorageService()

globalThis.remoteStorageService = remoteStorageService
