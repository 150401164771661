import { makeAutoObservable } from 'mobx'

import { chatApi } from '~/@common/api/chatApi.ts'
import { toastService } from '~/@common/services/toast.service.tsx'
import { readableStreamReaderUtils } from '~/@common/utils/readableStreamReaderUtils.ts'

export class AiTutorService {
  private _question: Question = emptyQuestion
  private _sessionId: string | null = null
  private _messages: Message[] = [new StartMessage()]

  constructor() {
    makeAutoObservable(this)
  }

  async loadAndSetSessionId() {
    this._sessionId = await chatApi.getSessionId()
  }

  get comment() {
    return this.message.comment
  }

  get message() {
    return this._messages[this._messages.length - 1]
  }

  get messages() {
    return this._messages
  }

  get recentMessage() {
    return this._messages[this._messages.length - 1]
  }

  addMessage(message: Message) {
    this._messages.push(message)
  }

  setQuestion(question: Question) {
    this._messages[this._messages.length - 1].setQuestion(question)
    this._question = question
  }

  get question() {
    return this._question
  }

  sendQuestion() {
    if (!this._sessionId) return

    this.addMessage(this.question.createMessage())
    this.message.send(this._sessionId)
  }

  close() {
    this.message.close()
    this._messages = [new StartMessage()]
  }
}

export interface Question {
  createMessage(): Message
}

type ConceptHintQuestionType = {
  problemId: number
  conceptImageUrl: string | null
  littleChapterImageUrl: string | null
  problemImageUrl: string
}

export class EmptyQuestion implements Question {
  createMessage() {
    return new EmptyMessage()
  }
}

export class ConceptHintQuestion implements Question {
  readonly _problemId: number
  readonly _conceptImageUrl: string | null
  readonly _littleChapterImageUrl: string | null
  readonly _problemImageUrl: string

  constructor(data: ConceptHintQuestionType) {
    this._problemId = data.problemId
    this._problemImageUrl = data.problemImageUrl
    this._conceptImageUrl = data.conceptImageUrl
    this._littleChapterImageUrl = data.littleChapterImageUrl
  }

  private get 개념이미지() {
    return this._conceptImageUrl ?? this._littleChapterImageUrl
  }

  get rawValue() {
    return {
      problemImageUrl: this._problemImageUrl,
      conceptImageUrl: this.개념이미지,
    }
  }

  createMessage() {
    return new ConceptHintMessage({ problemId: this._problemId })
  }
}

type FeedbackQuestionType = Omit<
  ConceptHintQuestionType,
  'conceptImageUrl' | 'littleChapterImageUrl'
>

export class AnalyzeQuestion implements Question {
  readonly _problemId: number
  readonly _problemImageUrl: string

  constructor(data: FeedbackQuestionType) {
    this._problemId = data.problemId
    this._problemImageUrl = data.problemImageUrl
  }

  get rawValue() {
    return {
      problemImageUrl: this._problemImageUrl,
    }
  }

  createMessage() {
    return new AnalyzeMessage({
      problemId: this._problemId,
      userHandwrittenNoteUrl: this._problemImageUrl,
    })
  }
}

export class CheckQuestion implements Question {
  readonly _problemId: number
  readonly _problemImageUrl: string

  constructor(data: FeedbackQuestionType) {
    this._problemId = data.problemId
    this._problemImageUrl = data.problemImageUrl
  }

  get rawValue() {
    return {
      problemImageUrl: this._problemImageUrl,
    }
  }

  createMessage() {
    return new CheckMessage({
      problemId: this._problemId,
      userHandwrittenNoteUrl: this._problemImageUrl,
    })
  }
}

export const emptyQuestion = new EmptyQuestion()

export interface Message {
  isLoading: boolean
  comment: string
  question: Question
  setQuestion(question: Question): void
  isReplyCompleted: boolean
  send(): void
  send(sessionId?: string): void
  close(): void
}

class EmptyMessage implements Message {
  private _question: Question = new EmptyQuestion()

  constructor() {
    makeAutoObservable(this)
  }

  get isLoading() {
    return false
  }

  get comment() {
    return ''
  }

  get question() {
    return this._question
  }

  setQuestion(question: Question) {
    this._question = question
  }

  get isReplyCompleted() {
    return true
  }

  send() {}
  close() {}
}

export class StartMessage implements Message {
  private _question: Question = new EmptyQuestion()
  private _isReplyCompleted = false
  private _fullComment =
    '안녕하세요. AI 튜터입니다.n' +
    '저에게 물어보고 싶은 항목을 눌러주세요!n' +
    '이 대화 내용을 선생님이 확인할 수 있어요 😎 '
  private _currentComment = ''
  private _typingTimer: NodeJS.Timeout | null = null

  constructor() {
    makeAutoObservable(this)
    this.typing()
  }

  private typing() {
    let index = 0
    this._typingTimer = setInterval(() => {
      if (index < this._fullComment.length) {
        this._currentComment +=
          this._fullComment.charAt(index) === 'n' ? '<br/>' : this._fullComment.charAt(index)
        index++
      } else {
        this._isReplyCompleted = true
        if (this._typingTimer) {
          clearInterval(this._typingTimer)
        }
      }
    }, 50)
  }

  get comment() {
    return this._currentComment
  }

  get question() {
    return this._question
  }

  setQuestion(question: Question) {
    this._question = question
  }

  get isLoading() {
    return false
  }

  get isReplyCompleted() {
    return this._isReplyCompleted
  }

  send() {}
  close() {}
}

export class MiddleMessage implements Message {
  private _question: Question = new EmptyQuestion()
  private _isReplyCompleted = false
  private commentList = [
    'AI 튜터의 도움이 더 필요하신가요?n 원하는 항목을 누르면 도와드릴게요!n',
    '또 다른 힌트가 필요하세요?n 원하는 힌트를 다시 선택해주세요!n',
    '추가 힌트가 필요하세요?n 원하는 힌트를 선택하시면 도움을 드릴게요!n',
    '다시 물어보고 싶은 내용을 선택해주세요.n AI 튜터가 힌트를 드릴게요.n',
    '질문은 언제든 환영이에요!n 원하는 힌트를 선택해주세요.n',
    '궁금한 점이 있으면 언제든 힌트를 요청해보세요!n AI 튜터가 도와드릴게요.n',
    "풀이를 인식해서 피드백을 드릴 수 있다는 걸 알고 계신가요?n '풀이분석', '풀이확인' 버튼을 눌러보세요!n",
    'AI 튜터는 지금 실험중이에요.n 조금씩 더 똑똑해지고 있으니, 원하는 질문이 있으면 언제든 불러주세요.n',
    '혼자서 공부할 때, 도움이 필요하면 언제든 불러주세요!n 원하는 힌트를 선택하면 도와드릴게요.n',
  ]
  private _fullComment = ''
  private _currentComment = ''
  private _typingTimer: NodeJS.Timeout | null = null

  constructor() {
    makeAutoObservable(this)
    this._fullComment = this.getRandomComment() + '이 대화 내용을 선생님이 확인할 수 있어요 😎'
    this.typing()
  }

  private typing() {
    let index = 0
    this._typingTimer = setInterval(() => {
      if (index < this._fullComment.length) {
        this._currentComment +=
          this._fullComment.charAt(index) === 'n' ? '<br/>' : this._fullComment.charAt(index)
        index++
      } else {
        this._isReplyCompleted = true
        if (this._typingTimer) {
          clearInterval(this._typingTimer)
        }
      }
    }, 50)
  }

  private getRandomComment() {
    const randomIndex = Math.floor(Math.random() * this.commentList.length)
    return this.commentList[randomIndex]
  }

  get comment() {
    return this._currentComment
  }

  get question() {
    return this._question
  }

  setQuestion(question: Question) {
    this._question = question
  }

  get isLoading() {
    return false
  }

  get isReplyCompleted() {
    return this._isReplyCompleted
  }

  send() {}
  close() {}
}

export class ConceptHintMessage implements Message {
  private _question: Question = new EmptyQuestion()
  private _comment = ''
  private _isReplyCompleted = false
  private _reader: ReadableStreamDefaultReader<string> | null = null
  private readonly _problemId: number

  constructor(data: { problemId: number }) {
    makeAutoObservable(this)
    this._problemId = data.problemId
  }

  get comment() {
    return this._comment
  }

  set comment(comment: string) {
    this._comment += comment
  }

  get question() {
    return this._question
  }

  setQuestion(question: Question) {
    this._question = question
  }

  get isLoading() {
    return true
  }

  get isReplyCompleted() {
    return this._isReplyCompleted && this.comment.length > 0
  }

  set isReplyCompleted(value: boolean) {
    this._isReplyCompleted = value
  }

  async send(sessionId?: string) {
    if (!sessionId) return

    try {
      const res = await chatApi.getHintConceptual(this._problemId, {
        sessionId,
      })

      if (res.body === null) return
      this._reader = res.body.pipeThrough(new TextDecoderStream()).getReader()

      await readableStreamReaderUtils.addMessageListener({
        reader: this._reader,
        onMessage: (value) => {
          this.comment = value
        },
        onDone: () => {
          this.isReplyCompleted = true
        },
      })
    } catch (e) {
      toastService.error('다시 시도해주세요.')
    } finally {
      this.isReplyCompleted = true
    }
  }

  close() {
    if (!this._reader) return
    this._reader.cancel()
  }
}

export class AnalyzeMessage implements Message {
  private _question: Question = new EmptyQuestion()
  private _comment = ''
  private _isReplyCompleted = false
  private _reader: ReadableStreamDefaultReader<string> | null = null
  private readonly _problemId: number
  private readonly _userHandwrittenNoteUrl: string

  constructor(data: { problemId: number; userHandwrittenNoteUrl: string }) {
    makeAutoObservable(this)
    this._problemId = data.problemId
    this._userHandwrittenNoteUrl = data.userHandwrittenNoteUrl
  }

  get comment() {
    return this._comment
  }

  set comment(comment: string) {
    this._comment += comment
  }

  get question() {
    return this._question
  }

  setQuestion(question: Question) {
    this._question = question
  }

  get isLoading() {
    return true
  }

  get isReplyCompleted() {
    return this._isReplyCompleted && this.comment.length > 0
  }

  set isReplyCompleted(value: boolean) {
    this._isReplyCompleted = value
  }

  async send(sessionId?: string) {
    if (!sessionId) return

    try {
      const res = await chatApi.getSolveTheStuckUp(this._problemId, {
        sessionId,
        userHandwrittenNoteUrl: this._userHandwrittenNoteUrl,
      })

      if (res.body === null) return
      this._reader = res.body.pipeThrough(new TextDecoderStream()).getReader()

      await readableStreamReaderUtils.addMessageListener({
        reader: this._reader,
        onMessage: (value) => {
          this.comment = value
        },
        onDone: () => {
          this.isReplyCompleted = true
        },
      })
    } catch (e) {
      toastService.error('다시 시도해주세요.')
    } finally {
      this.isReplyCompleted = true
    }
  }
  close() {
    if (!this._reader) return
    this._reader.cancel()
  }
}

export class CheckMessage implements Message {
  private _question: Question = new EmptyQuestion()
  private _comment = ''
  private _isReplyCompleted = false
  private _reader: ReadableStreamDefaultReader<string> | null = null
  private readonly _problemId: number
  private readonly _userHandwrittenNoteUrl: string

  constructor(data: { problemId: number; userHandwrittenNoteUrl: string }) {
    makeAutoObservable(this)
    this._problemId = data.problemId
    this._userHandwrittenNoteUrl = data.userHandwrittenNoteUrl
  }

  get comment() {
    return this._comment
  }

  set comment(comment: string) {
    this._comment += comment
  }

  get question() {
    return this._question
  }

  setQuestion(question: Question) {
    this._question = question
  }

  get isLoading() {
    return true
  }

  get isReplyCompleted() {
    return this._isReplyCompleted && this.comment.length > 0
  }

  set isReplyCompleted(value: boolean) {
    this._isReplyCompleted = value
  }

  async send(sessionId?: string) {
    if (!sessionId) return

    try {
      const res = await chatApi.getReviewTheSolution(this._problemId, {
        sessionId,
        userHandwrittenNoteUrl: this._userHandwrittenNoteUrl,
      })

      if (res.body === null) return
      this._reader = res.body.pipeThrough(new TextDecoderStream()).getReader()

      await readableStreamReaderUtils.addMessageListener({
        reader: this._reader,
        onMessage: (value) => {
          this.comment = value
        },
        onDone: () => {
          this.isReplyCompleted = true
        },
      })
    } catch (e) {
      toastService.error('다시 시도해주세요.')
    } finally {
      this.isReplyCompleted = true
    }
  }
  close() {
    if (!this._reader) return
    this._reader.cancel()
  }
}
