import { useEffect, useState } from 'react'

type ImageLoadStatus = 'beforeLoad' | 'loaded' | 'error'

export const useImageLoad = (
  url: string | undefined,
  opt?: { onLoad?: (e: Event) => void; onError: (e: Event | string) => void },
) => {
  const [imgStatus, setImgStatus] = useState<ImageLoadStatus>('beforeLoad')

  useEffect(() => {
    if (url) {
      const img = new Image()
      img.src = url
      img.onload = (e) => {
        setImgStatus('loaded')
        opt?.onLoad?.call(img, e)
      }

      img.onerror = (e) => {
        setImgStatus('error')
        opt?.onError?.call(img, e)
      }
    }
  }, [url, opt])

  return {
    imgStatus,
  }
}
