import { webviewService } from '~/@common/services'

import { localStorageService } from '../services/storage.service'

export const openLink = (url: string) => {
  if (localStorageService.device.ver) {
    webviewService.openBrowser(url)
    return
  }
  window.open(url, '_blank')
}
