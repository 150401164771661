import { outerLinks } from '~/@common/constants/strings'
import { colors } from '~/@common/styles'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton'
import { openLink } from '~/@common/utils/openLink'

export const GuideVideoButton = () => {
  return (
    <IconButton
      name="icon_help_line"
      title="사용가이드 보러가기"
      iconSize={26}
      iconStyle={{ color: colors.gray.$600 }}
      size={42}
      style={style}
      onClick={() => {
        openLink(outerLinks.학생앱_사용_가이드_링크)
      }}
    />
  )
}
const style = {
  borderRadius: '8px',
  border: `1px solid ${colors.gray.$300}`,
}
