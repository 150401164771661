import type { ISODate } from '@mathflat/shared/@types/date'
import type { ZeroValue } from '@mathflat/shared/@types/number'
import type { EmptyString } from '@mathflat/shared/@types/string'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'

import type { CurriculumDomain } from '~/@entities'
import type { WorkbookDomain } from '~/@entities/(Content)/Workbook/domain.ts'
import type { AllGrade, SchoolType, Semester } from '~/@entities/(SchoolSystem)/schema.ts'
import type { AcademyDomain } from '~/@entities/Academy/module.ts'
import type { DesignTemplateResponse } from '~/@entities/DesignTemplate/api'
import type { TextCoverDataItem } from '~/@entities/DesignTemplate/CoverData/dto'
import { CoverData, type MappedCoverData } from '~/@entities/DesignTemplate/CoverData/dto'
import type { DesignTemplate } from '~/@entities/DesignTemplate/dto'
import type { TeacherSchema } from '~/@entities/Teacher/schema'

import type { ContentLevel, PARTITION_TYPE } from '../module'

// private type으로 접근하지 마세요
type _WorkbookType = ValueOf<typeof WorkbookDomain.TYPE>
type _ZeroPage = ZeroValue | null | undefined
type _CurriculumConceptType = ValueOf<typeof CurriculumDomain.CURRICULUM_CONCEPT_TYPE>
type _SignatureType = ValueOf<typeof WorkbookDomain.SIGNATURE_TYPE>

class _WorkbookCommon {
  id: number
  type: _WorkbookType
  level: ContentLevel
  page: number | null | undefined
  schoolType: SchoolType
  grade: AllGrade
  semester: Semester
  title: string
  fulltitle: string
  assignmentFlag: boolean // 출제가능여부 (출제 되었다가 아님)
  autoScored: boolean
  coverType: number // TODO: 리터럴일 수 있음.
  partitionType: keyof typeof PARTITION_TYPE
  conceptFlag: boolean
  createDatetime: Date
  revision: 'CURRICULUM_15' | 'CURRICULUM_22' // 개정 - 교육과정 15, 22

  constructor(
    params: Omit<_WorkbookCommon, 'createDatetime' | 'formattedCreateDatetime'> & {
      createDatetime: ISODate | Date
    },
  ) {
    this.id = params.id
    this.type = params.type
    this.level = params.level
    this.page = params.page
    this.schoolType = params.schoolType
    this.grade = params.grade
    this.semester = params.semester
    this.title = params.title
    this.fulltitle = params.fulltitle
    this.assignmentFlag = params.assignmentFlag
    this.autoScored = params.autoScored
    this.coverType = params.coverType
    this.partitionType = params.partitionType
    this.conceptFlag = params.conceptFlag
    this.createDatetime = new Date(params.createDatetime)
    this.revision = params.revision
  }
}

export namespace Entity {
  export type CustomWorkbookCoverDataT = CustomSignatureWorkbookCoverDataT

  export class CustomWorkbook extends _WorkbookCommon {
    override type: Extract<_WorkbookType, typeof WorkbookDomain.TYPE.내교재>
    headtitle: string
    subtitle: string
    edition: EmptyString
    publisher: EmptyString
    pdfUrl: string
    answerPdfUrl: string
    minPage: number
    maxPage: number
    answerPage: number
    curriculumConceptType: _CurriculumConceptType
    academyId: AcademyDomain.Id
    teacherId: TeacherSchema.Id
    assigned: boolean | null // 출제되었는지 (withAssigned Params를 보내야함)
    coverData: CoverData<CustomWorkbookCoverDataT> | null = null
    innerDesignTemplateId: number = 1
    designTemplateId: DesignTemplate['id'] | null

    constructor(
      params:
        | (Omit<CustomWorkbook, 'createDatetime'> & {
            createDatetime: ISODate | Date
          })
        | (Omit<CustomWorkbook, 'createDatetime'> & {
            createDatetime: ISODate | Date
            coverData: DesignTemplateResponse<CustomWorkbookCoverDataT>['coverData'] | null
          }),
    ) {
      super(params)
      this.type = params.type
      this.headtitle = params.headtitle
      this.subtitle = params.subtitle
      this.edition = params.edition
      this.publisher = params.publisher
      this.pdfUrl = params.pdfUrl
      this.answerPdfUrl = params.answerPdfUrl
      this.minPage = params.minPage
      this.maxPage = params.maxPage
      this.answerPage = params.answerPage
      this.curriculumConceptType = params.curriculumConceptType
      this.academyId = params.academyId
      this.teacherId = params.teacherId
      this.assigned = params.assigned
      this.innerDesignTemplateId = params.innerDesignTemplateId
      this.designTemplateId = params.designTemplateId

      if (params.coverData) {
        this.coverData = new CoverData(params.coverData)
      }
    }
  }

  export class PublicWorkbook extends _WorkbookCommon {
    override type: Extract<_WorkbookType, typeof WorkbookDomain.TYPE.시중교재>
    override page: _ZeroPage
    headtitle: EmptyString
    subtitle: string
    edition: EmptyString
    publisher: string
    pdfUrl: null
    answerPdfUrl: null
    minPage: _ZeroPage
    maxPage: _ZeroPage
    answerPage: _ZeroPage
    pairX: number
    pairFlag: boolean

    constructor(
      params: Omit<PublicWorkbook, 'createDatetime'> & { createDatetime: ISODate | Date },
    ) {
      super(params)
      this.type = params.type
      this.page = params.page
      this.headtitle = params.headtitle
      this.subtitle = params.subtitle
      this.edition = params.edition
      this.publisher = params.publisher
      this.pdfUrl = params.pdfUrl
      this.answerPdfUrl = params.answerPdfUrl
      this.minPage = params.minPage
      this.maxPage = params.maxPage
      this.answerPage = params.answerPage
      this.pairX = params.pairX
      this.pairFlag = params.pairFlag
    }
  }

  export class SchoolWorkbook extends _WorkbookCommon {
    override type: Extract<_WorkbookType, typeof WorkbookDomain.TYPE.교과서>
    override page: _ZeroPage
    headtitle: EmptyString
    subtitle: string
    edition: string
    publisher: string
    pdfUrl: null
    answerPdfUrl: null
    minPage: _ZeroPage
    maxPage: _ZeroPage
    answerPage: _ZeroPage
    teacherId: TeacherSchema.Id
    pairX: number
    pairFlag: boolean
    assigned: boolean | null

    constructor(
      params: Omit<SchoolWorkbook, 'createDatetime'> & { createDatetime: ISODate | Date },
    ) {
      super(params)
      this.type = params.type
      this.page = params.page
      this.headtitle = params.headtitle
      this.subtitle = params.subtitle
      this.edition = params.edition
      this.publisher = params.publisher
      this.pdfUrl = params.pdfUrl
      this.answerPdfUrl = params.answerPdfUrl
      this.minPage = params.minPage
      this.maxPage = params.maxPage
      this.answerPage = params.answerPage
      this.teacherId = params.teacherId
      this.pairX = params.pairX
      this.pairFlag = params.pairFlag
      this.assigned = params.assigned
    }
  }

  export class SignatureWorkbook extends _WorkbookCommon {
    override type: Extract<_WorkbookType, typeof WorkbookDomain.TYPE.시그니처교재>
    override page: _ZeroPage
    headtitle: string
    subtitle: string
    featureType: WorkbookDomain.CustomSignatureFeatureType
    edition: EmptyString
    publisher: string
    pdfUrl: string
    answerPdfUrl: string
    minPage: _ZeroPage
    maxPage: _ZeroPage
    answerPage: _ZeroPage
    pairX: number
    pairFlag: boolean
    signatureType: _SignatureType
    thumbnailImageUrl: string
    signatureWorkbookFulltitle: string
    productId: number
    list: WorkbookDomain.AssignedStudent[]

    constructor(
      params: Omit<SignatureWorkbook, 'createDatetime'> & { createDatetime: ISODate | Date },
    ) {
      super(params)
      this.page = params.page
      this.type = params.type
      this.headtitle = params.headtitle
      this.subtitle = params.subtitle
      this.featureType = params.featureType
      this.edition = params.edition
      this.publisher = params.publisher
      this.pdfUrl = params.pdfUrl
      this.answerPdfUrl = params.answerPdfUrl
      this.minPage = params.minPage
      this.maxPage = params.maxPage
      this.answerPage = params.answerPage
      this.pairX = params.pairX
      this.pairFlag = params.pairFlag
      this.signatureType = params.signatureType
      this.thumbnailImageUrl = params.thumbnailImageUrl
      this.list = params.list
      this.productId = params.productId
      this.signatureWorkbookFulltitle = params.signatureWorkbookFulltitle
    }
  }

  type _CustomSignatureWorkbookCoverDataT = {
    headtitle: TextCoverDataItem // 대제목이 아니라 학년/학기/과목 이라는 충격적 사실
    subtitle: TextCoverDataItem<'subtitle'> | null
    title: TextCoverDataItem // 대제목
    academyName: TextCoverDataItem
    studentName: TextCoverDataItem
    sideTitle: TextCoverDataItem<'sideTitle'> // value가 empty일 수 있음
    backTopText: TextCoverDataItem
    backBottomText: TextCoverDataItem
  }

  export type CustomSignatureWorkbookCoverDataT =
    MappedCoverData<_CustomSignatureWorkbookCoverDataT>

  export class CustomSignatureWorkbook extends _WorkbookCommon {
    override type: Extract<_WorkbookType, typeof WorkbookDomain.TYPE.커스텀시그니처교재>
    override page: _ZeroPage
    headtitle: string
    subtitle: string
    featureType: WorkbookDomain.CustomSignatureFeatureType
    edition: EmptyString
    publisher: string
    pdfUrl: string
    answerPdfUrl: string
    minPage: _ZeroPage
    maxPage: _ZeroPage
    answerPage: _ZeroPage
    pairX: number
    pairFlag: boolean
    signatureType: _SignatureType
    thumbnailImageUrl: string
    thumbnailImageKey: string | null
    list: WorkbookDomain.AssignedStudent[]

    productId: number
    designTemplateId: DesignTemplate['id'] | null
    academyName: null
    solutionPdfUrl: string
    academyId: AcademyDomain.Id
    signatureWorkbookFulltitle: string
    signatureWorkbookId: number
    lastAssignDateTime: null
    lastUsePageNumber: null
    coverData: CoverData<CustomSignatureWorkbookCoverDataT> | null = null

    orderDatetime: Date | null
    orderStatus: 'PAYMENT_COMPLETED' | 'IN_PRODUCTION' | 'DELIVERY_COMPLETED' | 'ORDER_CANCEL'

    constructor({
      coverData,
      ...params
    }:
      | (Omit<
          CustomSignatureWorkbook,
          'createDatetime' | 'orderDatetime' | 'formattedCreateDatetime'
        > & {
          createDatetime: ISODate | Date
          orderDatetime?: ISODate | Date | null
        })
      | (Omit<
          CustomSignatureWorkbook,
          'createDatetime' | 'orderDatetime' | 'coverData' | 'formattedCreateDatetime'
        > & {
          createDatetime: ISODate | Date
          orderDatetime?: ISODate | Date | null
          coverData: DesignTemplateResponse<CustomSignatureWorkbookCoverDataT>['coverData'] | null
        })) {
      super(params)
      this.page = params.page
      this.type = params.type
      this.headtitle = params.headtitle
      this.subtitle = params.subtitle
      this.featureType = params.featureType
      this.edition = params.edition
      this.publisher = params.publisher
      this.pdfUrl = params.pdfUrl
      this.answerPdfUrl = params.answerPdfUrl
      this.minPage = params.minPage
      this.maxPage = params.maxPage
      this.answerPage = params.answerPage
      this.pairX = params.pairX
      this.pairFlag = params.pairFlag
      this.signatureType = params.signatureType
      this.thumbnailImageUrl = params.thumbnailImageUrl
      this.thumbnailImageKey = params.thumbnailImageKey
      this.list = params.list
      this.productId = params.productId
      this.designTemplateId = params.designTemplateId
      this.academyName = params.academyName
      this.solutionPdfUrl = params.solutionPdfUrl
      this.academyId = params.academyId
      this.signatureWorkbookFulltitle = params.signatureWorkbookFulltitle
      this.signatureWorkbookId = params.signatureWorkbookId
      this.lastAssignDateTime = params.lastAssignDateTime
      this.lastUsePageNumber = params.lastUsePageNumber
      this.coverData = coverData
        ? new CoverData<CustomSignatureWorkbookCoverDataT>(coverData)
        : null
      this.orderDatetime = params.orderDatetime ? new Date(params.orderDatetime) : null
      this.orderStatus = params.orderStatus
    }
  }

  // * Workbook *
  export type Workbook<T extends WorkbookDomain.Type = WorkbookDomain.Type> =
    T extends WorkbookDomain.TYPE['교과서']
      ? SchoolWorkbook
      : T extends WorkbookDomain.TYPE['시중교재']
        ? PublicWorkbook
        : T extends WorkbookDomain.TYPE['내교재']
          ? CustomWorkbook
          : T extends WorkbookDomain.TYPE['시그니처교재']
            ? SignatureWorkbook
            : T extends WorkbookDomain.TYPE['커스텀시그니처교재']
              ? CustomSignatureWorkbook
              : never
}
