import { WorkbookDomain } from '@mathflat/domain/@entities/(Content)/Workbook/domain.ts'
import { Entity as WorkbookEntity } from '@mathflat/domain/@entities/(Content)/Workbook/dto'
import type { Entity as WorkbookProblemEntity } from '@mathflat/domain/@entities/(Content)/Workbook/WorkbookProblem/dto'
import type { StudentDomain } from '@mathflat/domain/@entities/Student/domain.ts'
import type { MathflatApi } from '@mathflat/domain/@entities/StudentWorkbook/api.ts'
import type { Entity as StudentWorkbookEntity } from '@mathflat/domain/@entities/StudentWorkbook/dto'
import type { Pagination } from '@mathflat/shared/@types/mathflatApi'

import type { studentWorkbookDetailPathParams } from '../constants'
import { MAX_REQUEST_NUMBER } from '../constants'
import maxios from '../utils/maxios'

export const studentWorkbookApi = {
  getStudentWorkbookList: async (
    studentId: StudentDomain.Id,
    params: MathflatApi.Request.WorkbookAndStudentWorkbook,
  ) => {
    const { data } = await maxios.get<
      Pagination<MathflatApi.Response.WorkbookAndStudentWorkbook<WorkbookEntity.Workbook['type']>[]>
    >(`student-workbook/student/${studentId}`, {
      params: {
        size: 16,
        ...params,
      },
    })
    return {
      ...data,
      content: data.content.map(
        ({
          roundToRevisionRoundMap,
          studentWorkbook,
          recentRevisionId,
          recentRevisionRound,
          recentPageNumber,
          recentAssignedDatetime,
          ...workbook
        }) => {
          return {
            workbook:
              workbook.type === WorkbookDomain.TYPE.시중교재
                ? new WorkbookEntity.PublicWorkbook(workbook)
                : workbook.type === WorkbookDomain.TYPE.교과서
                  ? new WorkbookEntity.SchoolWorkbook(workbook)
                  : workbook.type === WorkbookDomain.TYPE.내교재
                    ? new WorkbookEntity.CustomWorkbook(workbook)
                    : workbook.type === WorkbookDomain.TYPE.커스텀시그니처교재
                      ? new WorkbookEntity.CustomSignatureWorkbook({
                          ...workbook,
                          orderDatetime: workbook.orderDatetime ?? undefined, // Ensure orderDatetime is not null
                        })
                      : new WorkbookEntity.SignatureWorkbook(workbook),
            roundToRevisionRoundMap,
            recentRevisionRound,
            recentPageNumber,
            studentWorkbook,
            recentRevisionId,
            recentAssignedDatetime,
          }
        },
      ),
    }
  },
  getStudentWorkbookDetail: async <T extends WorkbookDomain.Type = WorkbookDomain.Type>(
    studentId: StudentDomain.Id,
    params: Omit<typeof studentWorkbookDetailPathParams, 'pageNumber'>,
  ) => {
    const res = await maxios.get<MathflatApi.Response.StudentWorkbookDetail<T>>(
      `/student-workbook/student/${studentId}/${params.studentWorkbookId}/${params.revisionId}`,
      {
        params: { size: MAX_REQUEST_NUMBER },
      },
    )
    return res.data
  },
  getOnePageInStudentWorkbookDetail: async <T extends WorkbookProblemEntity.WorkbookProblem>(
    studentId,
    { studentWorkbookId, revisionId, progressId }: MathflatApi.Request.StudentWorkbookDetailPage,
  ) => {
    const res = await maxios.get<
      Pagination<MathflatApi.Response.StudentWorkbookDetailProblemScoring<T>[]>
    >(`/student-workbook/student/${studentId}/${studentWorkbookId}/${revisionId}/${progressId}`, {
      params: { size: MAX_REQUEST_NUMBER },
    })
    return res.data
  },
  문풀동시청기록: async (
    workbookInfo: {
      progressId: StudentWorkbookEntity.Progress['id']
      workbookProblemId: WorkbookProblemEntity.WorkbookProblem['id']
    },
    payload: MathflatApi.Request.StudentWorkbookVideoAssistPayload,
  ) => {
    return await maxios.patch(
      `/student-workbook/${workbookInfo.progressId}/${workbookInfo.workbookProblemId}/assist`,
      [payload],
    )
  },
  제출하기: {
    자동채점: async (
      progressId: StudentWorkbookEntity.Progress['id'],
      payload: MathflatApi.Request.StudentWorkbookAutoScoringPayload[],
    ) => {
      return await maxios.patch(`/student-workbook/auto-scoring`, payload, {
        params: {
          progressId,
          // TODO: 과도기 임시코드
          version: 'v2',
        },
      })
    },
    일반채점: async (payload: MathflatApi.Request.StudentWorkbookScoringPayload[]) => {
      return await maxios.patch(`/student-workbook/scoring`, payload, {
        params: {
          // TODO: 과도기 임시코드
          version: 'v2',
        },
      })
    },
  },
}
