import { observer } from 'mobx-react'
import type { CSSProperties, FC, ReactNode } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'

import { PIXEL_RATIO } from '../constants'
import ScalableContent from '../scalableContent/ScalableContent'
import type { FitMode } from '../scalableContent/scalableContent.types'
import type { Size } from '../types'
import type { NoteData } from './handwrittenNote.types'
import HandwrittenNotePath from './HandwrittenNotePath'
import HandwrittenNoteService from './service/HandwrittenNote.service'

const kind = `mn__ReadOnlyHandwrittenNote`

interface Props {
  noteData?: NoteData
  contentSize?: Size // noteData가 없으면 contentSize 필수
  preventScale?: boolean // panning, pinch to zoom 방지
  isHidden?: boolean // 필기 숨기기
  fitMode?: FitMode
  containerPadding?: number
  zoomLevel?: number
  noteScaleResetKey?: number
  isSlideToNoteRect?: boolean
  preventResizeObserver?: boolean
  preventOverflow?: boolean
  style?: CSSProperties
  contentWrapperStyle?: CSSProperties
  contentStyle?: CSSProperties
  children?: ReactNode
}

const ReadOnlyHandwrittenNote: FC<Props> = ({
  noteData,
  contentSize: initialContentSize,
  preventScale,
  isHidden,
  fitMode = 'exact',
  containerPadding = 0,
  zoomLevel = 1,
  noteScaleResetKey,
  isSlideToNoteRect,
  preventResizeObserver,
  preventOverflow,
  style,
  contentWrapperStyle,
  contentStyle,
  children,
}) => {
  const serviceRef = useRef(new HandwrittenNoteService())
  const service = serviceRef.current
  const containerRef = useRef<HTMLDivElement>(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  const contentSize = useMemo(() => {
    if (!noteData) {
      return initialContentSize
    }
    return {
      width: noteData.viewBox[0],
      height: noteData.viewBox[1],
    }
  }, [noteData, initialContentSize])

  useEffect(() => {
    if (!noteData) {
      return
    }
    service.reset()
    service.noteData.fromJSON(noteData)
    service.setViewBoxSize(contentSize)
  }, [contentSize, noteData, service])

  const targetRect = useMemo(() => {
    if (!noteData || !isSlideToNoteRect) {
      return
    }
    const paths = noteData.paths
    const targetItems: Array<{
      indexes: number[]
      area: number
    }> = [
      {
        indexes: [],
        area: 0,
      },
    ]
    paths.forEach((path, index, list) => {
      if (path.pathRect) {
        let delay: number | undefined = undefined
        if (path.createdAt && list[index - 1]?.updatedAt) {
          delay = path.createdAt - list[index - 1].updatedAt!

          if (delay > 1000) {
            targetItems.push({
              indexes: [],
              area: 0,
            })
          }
        }
        targetItems[targetItems.length - 1].indexes.push(index)
        targetItems[targetItems.length - 1].area += path.pathRect.width * path.pathRect.height
      }
    })
    const maxAreaPaths = targetItems
      .filter((item) => item.indexes.length)
      .sort((a, b) => b.area - a.area)[0]

    if (!maxAreaPaths) {
      return
    }

    let minX = Number.MAX_SAFE_INTEGER
    let maxX = Number.MIN_SAFE_INTEGER
    let minY = Number.MAX_SAFE_INTEGER
    let maxY = Number.MIN_SAFE_INTEGER

    for (const index of maxAreaPaths.indexes) {
      const rect = paths[index]?.pathRect
      if (!rect) {
        continue
      }
      minX = Math.min(minX, rect.x)
      maxX = Math.max(maxX, rect.x + rect.width)
      minY = Math.min(minY, rect.y)
      maxY = Math.max(maxY, rect.y + rect.height)
    }
    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    }
  }, [isSlideToNoteRect, noteData])

  if (!noteData && !contentSize) {
    return null
  }

  const viewBoxSizeString = contentSize
    ? `0 0 ${contentSize.width * PIXEL_RATIO} ${contentSize.height * PIXEL_RATIO}`
    : undefined

  const needPreventOverflow = isHidden || preventScale || preventOverflow
  const allowDefaultTouchAction = preventScale && preventOverflow

  return (
    <div
      ref={containerRef}
      data-component={kind}
      style={{
        overflow: needPreventOverflow ? 'hidden' : 'auto',
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        position: 'relative',
        ...style,
      }}
    >
      {isReady && containerRef.current && (
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            ...contentWrapperStyle,
          }}
        >
          <ScalableContent
            containerEl={containerRef.current}
            contentSize={contentSize}
            isWritingMode={false}
            preventScale={preventScale}
            fitMode={fitMode}
            containerPadding={containerPadding}
            zoomLevel={zoomLevel}
            targetRect={targetRect}
            allowDefaultTouchAction={allowDefaultTouchAction}
            preventResizeObserver={preventResizeObserver}
            noteScaleResetKey={noteScaleResetKey}
          >
            <div
              style={{
                ...contentStyle,
              }}
            >
              {children}
              {noteData && (
                <>
                  <svg
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      touchAction: allowDefaultTouchAction ? 'auto' : 'none',
                      display: isHidden ? 'none' : 'unset',
                      width: `${PIXEL_RATIO * 100}%`,
                      height: `${PIXEL_RATIO * 100}%`,
                      transform: `scale(${1 / PIXEL_RATIO})`,
                      transformOrigin: '0 0',
                    }}
                    viewBox={viewBoxSizeString}
                  >
                    {service.noteData.visiblePathItems.map((item) => (
                      <HandwrittenNotePath key={item.id} item={item} service={service} />
                    ))}
                  </svg>
                </>
              )}
            </div>
          </ScalableContent>
        </div>
      )}
    </div>
  )
}

export default observer(ReadOnlyHandwrittenNote)
