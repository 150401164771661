import * as Schema from '@effect/schema/Schema'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import clsx from 'clsx'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import { Fragment, useCallback, useEffect, useRef } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router'

import { routeName } from '~/@common/constants'
import { useImageLoad } from '~/@common/hooks/useImageLoad'
import useInfiniteScroll from '~/@common/hooks/useInfiniteScroll'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import { colors } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types'
import { Card } from '~/@common/ui/(Card)'
import { LearningContentCardAsChild } from '~/@common/ui/(Card)/LearningContentCard'
import Badge from '~/@common/ui/Badge/Badge'
import { Icon } from '~/@common/ui/Icon/Icon'
import Modal from '~/@common/ui/modal/Modal'
import { ProgressBar } from '~/@common/ui/ProgressBar/ProgressBar.tsx'
import Table from '~/@common/ui/Table/Table'
import type { StudentWorkbookListItem } from '~/@entities/StudentWorkbook/StudentWorkbook.dto'
import {
  STUDENT_WORKBOOK_LIST_TYPE,
  StudentWorkbookListPathParamSchema,
  StudentWorkbookListTypeSchema,
  type StudentWorkbookPathParamT,
} from '~/@entities/StudentWorkbook/StudentWorkbook.schema'

import { StudentWorkbookListService } from '../../@service/StudentWorkbookList.service'
import S from './StudentWorkbookList.style'
import StudentWorkbookTableSkeleton from './StudentWorkbookTableSkeleton'

const StudentWorkbookList = () => {
  const service = useRepository(StudentWorkbookListService)
  const params = useParams<StudentWorkbookPathParamT>()
  const navigate = useNavigate()
  const lastListItemRef = useRef<HTMLTableRowElement>(null)

  let type: ValueOf<typeof STUDENT_WORKBOOK_LIST_TYPE> = STUDENT_WORKBOOK_LIST_TYPE.일반교재

  try {
    const parseParam = Schema.parseSync(StudentWorkbookListPathParamSchema)
    const { studentWorkbookListType } = parseParam(params)
    const parseType = Schema.parseSync(StudentWorkbookListTypeSchema)
    type = parseType(studentWorkbookListType)
  } catch (e) {
    navigate(routeName.student.generalStudentWorkbookList)
  }

  const studentWorkbookList = service.getWorkbookListByType(type)

  const loadMoreWorkbookList = useCallback(async () => {
    if (service.checkIsLastPageByType(type)) {
      return
    }

    try {
      if (commonRepo.studentId) {
        service.setNextPage(type)
        await service.loadStudentWorkbookList(type)
      }
    } catch (err) {
      errorHandlerService.handle(err)
    }
  }, [service, type])

  useInfiniteScroll(lastListItemRef, loadMoreWorkbookList)

  const moveWorkbookDetail: SubWorkbookRowProps['onClick'] = (studentWorkbook) => {
    const routeType =
      params.studentWorkbookListType === 'custom-signature'
        ? routeName.student.studentWorkbookViewer
        : routeName.student.studentWorkbookDetail

    navigate(
      generatePath(routeType, {
        studentWorkbookId: String(studentWorkbook.studentWorkbookId),
        revisionId: String(studentWorkbook.roundId),
        pageNumber: String(studentWorkbook.recentPageNumber),
      }),
    )
  }

  const moveConditionalWorkbookDetail: MainWorkbookRowProps['onClick'] = (
    studentWorkbook,
    workbookCount,
  ) => {
    if (workbookCount > 1) {
      modalService.openModal(
        <Modal.Confirm.Positive
          confirm={{
            children: '학습하기',
            onClick: () => {
              moveWorkbookDetail(studentWorkbook)
              modalService.closeModal()
            },
          }}
        >
          가장 최근 회차의 교재를 학습하시겠어요?
        </Modal.Confirm.Positive>,
        { modalName: '교재 재출제 시 최근 회차 학습 묻는 컨펌 모달' },
      )
      return
    }
    moveWorkbookDetail(studentWorkbook)
  }

  useEffect(() => {
    service.resetStudentWorkbookListByType(type)
    service.loadStudentWorkbookList(type)
  }, [service, params, type])

  if (service.isLoading) {
    return <StudentWorkbookTableSkeleton />
  } else if (studentWorkbookList.length === 0) {
    return (
      <S.NoData className="student-workbook-list-no-data">
        <div className="purple-icon">
          <Icon name="icon_book_opened" size={24} color={colors.white} />
        </div>
        <div className="guide-text">
          {type === STUDENT_WORKBOOK_LIST_TYPE.일반교재 ? (
            <p>출제된 교재가 없습니다.</p>
          ) : (
            <p>
              {commonRepo.isSchoolflat ? '학교' : '학원'}에서 구매한 시그니처 교재가 없어요
              <br />
              선생님께 시그니처 교재 구매를 요청해보세요
            </p>
          )}
        </div>
      </S.NoData>
    )
  }

  return (
    <S.StudentWorkList className="student-workbook-list">
      <ParentComponent>
        {studentWorkbookList.map((studentWorkbooks) => {
          return (
            <Fragment key={studentWorkbooks[0].roundId}>
              <MainWorkbookRow
                workbookType={type}
                studentWorkbook={studentWorkbooks[0]}
                workbookCount={studentWorkbooks.length}
                onClick={moveConditionalWorkbookDetail}
              />
              {studentWorkbooks.length > 1 &&
                studentWorkbooks.map((studentWorkbook, index) => {
                  const isRecentRound = index === 0
                  return (
                    <Fragment key={studentWorkbook.roundId}>
                      <SubWorkbookRow
                        isRecentRound={isRecentRound}
                        studentWorkbook={studentWorkbook}
                        onClick={moveWorkbookDetail}
                      />
                    </Fragment>
                  )
                })}
            </Fragment>
          )
        })}
        <tr ref={lastListItemRef}></tr>
      </ParentComponent>
    </S.StudentWorkList>
  )
}

type MainWorkbookRowProps = {
  workbookType: ValueOf<typeof STUDENT_WORKBOOK_LIST_TYPE>
  studentWorkbook: StudentWorkbookListItem
  workbookCount: number
  onClick: (studentWorkbook: StudentWorkbookListItem, workbookCount: number) => void
}

const MainWorkbookRow = ({
  workbookType,
  studentWorkbook,
  workbookCount,
  onClick,
}: MainWorkbookRowProps) => {
  const { isMobile } = useStudentAppMediaQuery()
  const { imgStatus } = useImageLoad(studentWorkbook.thumbnailImageUrl)

  return (
    <>
      {isMobile ? (
        <Card className="student-workbook-main-row-wrapper">
          <div
            className="student-workbook-main-row"
            onClick={() => onClick(studentWorkbook, workbookCount)}
          >
            {studentWorkbook.thumbnailImageUrl && (
              <div className="image-wrapper">
                <img
                  src={studentWorkbook.thumbnailImageUrl}
                  alt={`시그니처 교재 ${studentWorkbook.교재명}의 썸네일 이미지`}
                />
              </div>
            )}
            <div className="student-workbook-main-row-info">
              <div className="student-workbook-main-row-info-head">
                <Icon name="icon_book_read" size={12} color={colors.blue.$500} />
                <span className="workbook-type">
                  {workbookType === 'custom-signature' && '시그니처'} 교재
                </span>
                <span className="date">
                  {workbookCount === 1 && format(studentWorkbook.출제일, 'yy.MM.dd')}
                </span>
              </div>
              <div className="title-wrapper">
                <h4 className="title">
                  <span className="text-wrapper">{studentWorkbook.교재명}</span>
                </h4>
              </div>

              {workbookCount === 1 && (
                <ProgressBar
                  className="progress-bar"
                  percent={studentWorkbook.진척도}
                  isOutDated={false}
                />
              )}
            </div>
          </div>
        </Card>
      ) : (
        <Table.Row
          ratio="30px 80px auto 176px"
          className="student-workbook-list-row"
          onClick={() => onClick(studentWorkbook, workbookCount)}
        >
          <Table.Item className="col1">
            <Icon name="icon_book_opened" size={20} color={colors.gray.$500} />
          </Table.Item>
          <Table.Item className="col2">
            <span className="date">
              {workbookCount === 1 && format(studentWorkbook.출제일, 'yy.MM.dd')}
            </span>
          </Table.Item>
          <Table.Item className="col3">
            {studentWorkbook.thumbnailImageUrl && imgStatus !== 'error' ? (
              <div className="image-wrapper">
                <img
                  src={studentWorkbook.thumbnailImageUrl}
                  alt={`시그니처 교재 ${studentWorkbook.교재명}의 썸네일 이미지`}
                />
              </div>
            ) : (
              <div className="icon-wrapper">
                <Icon
                  name="icon_photo_album"
                  size={20}
                  color="var(--NewColors-Slate-300, #CBD5E1)"
                />
              </div>
            )}
            <div className="title-wrapper">
              <div className="title">
                <span className="text-wrapper">{studentWorkbook.교재명}</span>
              </div>
              {workbookCount === 1 && studentWorkbook.autoScored && (
                <div className="sub-title">자동채점</div>
              )}
            </div>
          </Table.Item>
          <Table.Item className="col4">
            {workbookCount === 1 ? (
              <ProgressBar
                className="progress-bar"
                percent={studentWorkbook.진척도}
                isOutDated={false}
              />
            ) : (
              '-'
            )}
          </Table.Item>
        </Table.Row>
      )}
    </>
  )
}

type SubWorkbookRowProps = {
  studentWorkbook: StudentWorkbookListItem
  isRecentRound: boolean
  onClick: (studentWorkbook: StudentWorkbookListItem) => void
}

const SubWorkbookRow = ({ isRecentRound, studentWorkbook, onClick }: SubWorkbookRowProps) => {
  const { isMobile } = useStudentAppMediaQuery()
  return (
    <>
      {isMobile ? (
        <LearningContentCardAsChild wrapperStyle={{ gap: '10px' }}>
          <div onClick={() => onClick(studentWorkbook)} className="student-workbook-sub-row">
            <span className="date">{format(studentWorkbook.출제일, 'yy.MM.dd')}</span>
            <div className="student-workbook-sub-row-info">
              <span className={clsx('workbook-round', isRecentRound && 'primary')}>
                {studentWorkbook.round}회차
              </span>
              <ProgressBar
                className="progress-bar"
                percent={studentWorkbook.진척도}
                isOutDated={!isRecentRound}
              />
            </div>
          </div>
        </LearningContentCardAsChild>
      ) : (
        <Table.Row
          key={studentWorkbook.roundId}
          ratio="30px 80px auto 176px"
          className="student-workbook-list-row"
          onClick={() => onClick(studentWorkbook)}
        >
          <Table.Item className="col1">
            <Icon name="icon_inside_point" size={20} color={colors.gray.$500} />
          </Table.Item>
          <Table.Item className="col2">
            <span className="date">{format(studentWorkbook.출제일, 'yy.MM.dd')}</span>
          </Table.Item>
          <Table.Item className="col3">
            <div className="title-wrapper">
              <div className="title">
                <Badge
                  theme={isRecentRound ? 'secondaryBlue' : 'secondaryGray'}
                  style={{ marginRight: 10 }}
                >
                  {studentWorkbook.round}회차
                </Badge>
                <span className="text-wrapper"> {studentWorkbook.교재명}</span>
              </div>
              {studentWorkbook.autoScored && (
                <div className="sub-title">
                  <span>자동채점</span>
                </div>
              )}
            </div>
          </Table.Item>
          <Table.Item className="col4">
            <ProgressBar
              className="progress-bar"
              percent={studentWorkbook.진척도}
              isOutDated={!isRecentRound}
            />
          </Table.Item>
        </Table.Row>
      )}
    </>
  )
}

const ParentComponent = ({ children }: DefaultProps) => {
  const { isMobile } = useStudentAppMediaQuery()
  return (
    <>
      {isMobile ? (
        <div className="student-workbook-list">{children}</div>
      ) : (
        <Table className="student-workbook-list">
          <Table.Header className="student-workbook-list-table-header" ratio="30px 80px auto 176px">
            <Table.HeaderItem className="col1" />
            <Table.HeaderItem className="col2">출제일</Table.HeaderItem>
            <Table.HeaderItem className="col3">교재명</Table.HeaderItem>
            <Table.HeaderItem className="col4">진척도</Table.HeaderItem>
          </Table.Header>
          <Table.Body className="student-workbook-list-body">{children}</Table.Body>
        </Table>
      )}
    </>
  )
}
export default observer(StudentWorkbookList)
