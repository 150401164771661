import { CONTENT_STATUS } from '@mathflat/domain/@entities/(Content)/module'
import { WorkbookDomain } from '@mathflat/domain/@entities/(Content)/Workbook/domain.ts'
import type { Entity as WorkbookEntity } from '@mathflat/domain/@entities/(Content)/Workbook/dto'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import { makeAutoObservable } from 'mobx'

export type ProblemScoringViewOptionParams<T extends '학습모듈' | 'NOT_학습모듈'> = {
  content:
    | {
        status: ValueOf<typeof CONTENT_STATUS>
        autoScored: boolean
        kind: 'WORKSHEET'
        type?: WorksheetEntity.Worksheet['type']
        worksheetId: WorksheetEntity.Worksheet['id']
      }
    | {
        status: ValueOf<typeof CONTENT_STATUS>
        autoScored: boolean
        kind: 'WORKBOOK'
        type: WorkbookEntity.Workbook['type']
      }
  studentAppSetting: T extends 'NOT_학습모듈'
    ? {
        일반채점_채점전정답공개: boolean
        채점후정답해설공개: boolean
        채점전문풀동공개: boolean
        채점후문풀동공개: boolean
      }
    : null
  문제같이보기?: boolean
  오답_모르는문제만보기?: boolean
  안푼문제만보기?: boolean
}

// 코드리뷰 내용 - 추후 학습모듈/NOT_학습모듈 외의 분기가 생길 경우, 제네릭 제거 후 interface로 빼고, 각각의 클래스로 분리
export class ProblemScoringViewOption<T extends '학습모듈' | 'NOT_학습모듈'> {
  content: {
    status: ValueOf<typeof CONTENT_STATUS>
    autoScored: boolean
    kind: 'WORKSHEET' | 'WORKBOOK'
    type?: WorksheetEntity.Worksheet['type'] | WorkbookEntity.Workbook['type']
    worksheetId?: WorksheetEntity.Worksheet['id']
  }
  studentAppSetting: T extends 'NOT_학습모듈'
    ? {
        일반채점_채점전정답공개: boolean
        채점후정답해설공개: boolean
        채점전문풀동공개: boolean
        채점후문풀동공개: boolean
      }
    : null
  문제같이보기 = true
  오답_모르는문제만보기 = false
  안푼문제만보기 = false

  constructor(params: ProblemScoringViewOptionParams<T>) {
    makeAutoObservable(this)
    this.content = params.content
    this.studentAppSetting = params.studentAppSetting
    this.문제같이보기 = params.문제같이보기 ?? false
    this.오답_모르는문제만보기 = params.오답_모르는문제만보기 ?? false
    this.안푼문제만보기 = params.안푼문제만보기 ?? false
  }

  static isScorable<T extends '학습모듈' | 'NOT_학습모듈'>(
    content: Pick<ProblemScoringViewOptionParams<T>['content'], 'autoScored' | 'status'>,
    studentAppSetting: ProblemScoringViewOptionParams<T>['studentAppSetting'],
  ) {
    if (studentAppSetting) {
      if (!content.autoScored) {
        if (content.status !== CONTENT_STATUS.전체채점) {
          return studentAppSetting.일반채점_채점전정답공개
        }
      }
    }
    return true
  }

  get 채점불가능() {
    return !ProblemScoringViewOption.isScorable(this.content, this.studentAppSetting)
  }

  get 채점완료() {
    return this.content.status === CONTENT_STATUS.전체채점
  }

  get 채점완료_혹은_채점불가능() {
    return this.채점완료 || this.채점불가능
  }

  get hasCopyRight() {
    if (this.content.kind === 'WORKBOOK') {
      switch (this.content.type) {
        case WorkbookDomain.TYPE.시중교재:
        case WorkbookDomain.TYPE.교과서:
        case WorkbookDomain.TYPE.커스텀시그니처교재:
          return true
        default:
          return false
      }
    }
  }

  get 오답_모르는문제만보기_노출여부() {
    return this.content.status !== CONTENT_STATUS.채점전 && this.content.type !== 'MAAT'
  }

  get 안푼문제만보기_노출여부() {
    return (
      (!this.채점불가능 && this.content.status === CONTENT_STATUS.채점전) ||
      (!this.채점불가능 &&
        this.content.type === 'MAAT' &&
        this.content.status !== CONTENT_STATUS.전체채점)
    )
  }

  get 문제같이보기_노출여부() {
    return !this.hasCopyRight
  }

  get 가이드_노출() {
    if (
      !this.채점불가능 &&
      this.content.status !== CONTENT_STATUS.전체채점 &&
      !this.content.autoScored
    ) {
      return '가이드_일반채점'
    }
    if (
      this.content.status !== CONTENT_STATUS.채점전 &&
      !this.studentAppSetting?.채점후정답해설공개
    ) {
      return '가이드_비공개'
    }
  }

  onToggle(
    params: Partial<{
      문제같이보기: boolean
      오답_모르는문제만보기: boolean
      안푼문제만보기: boolean
    }>,
  ) {
    const _params = {
      문제같이보기: this.문제같이보기,
      오답_모르는문제만보기: this.오답_모르는문제만보기,
      안푼문제만보기: this.안푼문제만보기,
    }

    Object.assign(_params, params)

    this.문제같이보기 = _params.문제같이보기
    this.오답_모르는문제만보기 = _params.오답_모르는문제만보기
    this.안푼문제만보기 = _params.안푼문제만보기
  }

  changeStudentWorksheetStatus(status: ValueOf<typeof CONTENT_STATUS>) {
    this.content.status = status
  }
}
