import { observer } from 'mobx-react'
import { isAndroid, isChrome, isIOS, isSafari } from 'react-device-detect'

import { authApi } from '~/@common/api'
import { outerLinks } from '~/@common/constants/strings'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { errorHandlerService } from '~/@common/services'
import authService from '~/@common/services/auth.service'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import { colors } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'
import type { IconNames } from '~/@common/ui/Icon/iconNames.type'
import Modal from '~/@common/ui/modal/Modal'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import { openLink } from '~/@common/utils/openLink'
import GlobalHeader from '~/@pages/@widgets/(Navigation)/GlobalHeader/GlobalHeader'

import OpinionModal from '../@widgets/(Navigation)/GNB/OpinionModal'
import PasswordChange from '../@widgets/PasswordChange.widget'
import { S } from '.'

const ProfilePage = observer(() => {
  const { student, studentSchoolTypeWithGrade } = commonRepo
  const isApp = (isAndroid || isIOS) && !isSafari && !isChrome
  const { isMobile } = useStudentAppMediaQuery()

  return (
    <>
      {!isMobile && (
        <PortalRootLayoutHeader>
          <GlobalHeader SlotCenter="내정보" />
        </PortalRootLayoutHeader>
      )}
      <S.Wrapper>
        <S.Profile>
          <div className="profile-box">
            <div className="profile-content">
              <header>
                <h3 className="name">{student?.name}</h3>
              </header>
              <div className="info">
                <div className="info-label">
                  <h4>학년</h4>
                  <h4>아이디</h4>
                  <h4>출석 번호</h4>
                </div>
                <div className="info-value">
                  <p>{studentSchoolTypeWithGrade}</p>
                  <p>{student?.loginId}</p>
                  <p>{student?.attendanceCode}</p>
                </div>
              </div>
            </div>
            <div className="button-container">
              <button
                className="password-change"
                onClick={() => {
                  modalService.openModal(
                    { content: <PasswordChange />, header: '비밀번호 변경' },
                    {
                      modalName: '비밀번호 변경',
                      hasCloseButton: isMobile,
                      headerOption: { hasBottomBorder: false },
                    },
                  )
                }}
              >
                <Icon name="icon_edit" size={18} color={colors.gray.$700} />
                <p>비밀번호 변경</p>
              </button>
              <button
                className="password-change"
                onClick={() => {
                  modalService.openModal(
                    <Modal.Confirm.Negative
                      cancel={{ children: '취소' }}
                      confirm={{
                        onClick: () => {
                          if (!isApp) {
                            authApi
                              .logout()
                              .then(() => {
                                authService.logout()
                                authService.requestLogout()
                              })
                              .catch((error) => {
                                errorHandlerService.handle(error)
                              })
                              .finally(() => {
                                modalService.closeModal()
                              })
                            return
                          }

                          modalService.closeModal()
                          authService.logout()
                        },
                        children: '로그아웃',
                      }}
                    >
                      로그아웃 하시겠습니까?
                    </Modal.Confirm.Negative>,
                    { modalName: '로그아웃 모달' },
                  )
                }}
              >
                <Icon name="icon_unlock" size={18} color={colors.gray.$700} />
                <p>로그아웃</p>
              </button>
            </div>
          </div>
        </S.Profile>
        {isMobile && (
          <S.Etc>
            <ListButton
              iconName="icon_heart_comment"
              text="의견 전달하기"
              onClick={() =>
                modalService.openModal(<OpinionModal />, {
                  hasCloseButton: true,
                  modalName: 'opinion-modal',
                  modalStyle: {
                    padding: '0px',
                  },
                })
              }
            />
            {/* Tip: 가이드 동영상 준비 되어있지 않아, 스쿨플랫에서는 프리미엄 학생앱 사용 가이드 노션 페이지로 대체 */}
            <ListButton
              iconName="icon_help"
              text="사용가이드 보기"
              onClick={() => {
                openLink(outerLinks.학생앱_사용_가이드_링크)
              }}
            />
          </S.Etc>
        )}
      </S.Wrapper>
    </>
  )
})

const ListButton = ({
  iconName,
  text,
  onClick,
}: {
  iconName: IconNames
  text: string
  onClick?: () => void
}) => {
  return (
    <button className="etc-card" onClick={onClick}>
      <Icon name={iconName} size={18} color={colors.gray.$700} />
      <p>{text}</p>
      <Icon name="icon_chevron_right" size={18} color={colors.gray.$700} />
    </button>
  )
}

export default ProfilePage
